import React, { useState, createRef } from "react";
import { Box, Flex, FormControl, Container, Image, FormLabel, Spinner, Button, Input, FormHelperText, Text, Link, useToast, useBoolean, useMediaQuery, Grid, GridItem, useColorModeValue, Center, VStack, HStack, Textarea, Divider, Stack } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import miniBanner from "./miniBanner.jpg";
import axios from "axios";
import MaskedInput from 'react-maskedinput';

export default function Sac(){

    const navigate = useNavigate();
    const noturno = useColorModeValue(false, true);
    const [ maiorQue1024 ] = useMediaQuery('(min-width: 1024px)');
    const [ menorQue768 ] = useMediaQuery('(max-width: 767px)');
    const [dados, alterarDados] = useState({
        nome: "",
        email: "", 
        telefone: "", 
        mensagem: ""
    });
    const [enviando, alterarEnviando] = useState(false);
    const [enviado, alterarEnviado] = useState(false);
    let telCelInput = createRef();
    
    return <>
        <Box 
            h={"140px"}
            position={"relative"}
        >
            <Image src={miniBanner} display={"inline"} height={"100%"} width={`100%`} objectFit={"cover"} />
            <Center
                position={"absolute"}
                backdropFilter={noturno ? "sepia(0.8) grayscale(1) contrast(1.4)" : "sepia(0.8)"}
                top={0}
                left={0}
                bottom={0}
                right={0}
            >
                <Text textShadow={"0 0 10px #4a4545"} fontWeight={"bold"} fontSize={"4xl"} color="gray.100">
                    { menorQue768 ? "SAC" : "SERVIÇO DE ATENDIMENTO AO CLIENTE"}
                </Text>
            </Center>
        </Box>
        <Container 
            maxW='container.lg'
            mt={0}
            hidden={enviado}
        >
            <Center my={10}>
                Utilize o formulário de SAC para nos enviar suas sugestões sobre o passeio
            </Center>
            <Center mb={10} w="100%">
                <Stack
                    gap={5}     
                    alignItems="start"
                    direction={maiorQue1024 ? "row" : "column"}
                >
                    <Box alignSelf={"center"}>
                        <Text textAlign={!maiorQue1024 ? "center" : "right"}  fontSize="sm" >
                            Museu Ferroviário<br/>
                            Av. Pedro Zapelini, 2200<br/>
                            Tubarão - SC, 88702-300<br/><br/>
                            {/* 0800 591 1605 mobex<br/> */}
                            {/* 166 ANTT<br/><br/> */}
                            
                            <Link target="_blank" href="mailto:setorcomercialmft@gmail.com">setorcomercialmft@gmail.com</Link>
                        </Text>
                    </Box>

                    {maiorQue1024 && 
                        <Divider orientation='vertical' h={200} alignSelf="center" />
                    }
                    
                    <Box
                        minW={menorQue768 ? "270px" : "400px"}
                    >

                        <FormControl mb={3}>
                            <FormLabel fontSize={"md"} htmlFor='nome'>Nome</FormLabel>
                            <Input
                                size="sm"
                                value={dados.nome}
                                maxLength={100}
                                onChange={e => {
                                    alterarDados({...dados, nome: e.target.value});
                                }}
                            />
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel fontSize={"md"} htmlFor='email'>Email</FormLabel>
                            <Input
                                type='email'
                                size="sm"
                                maxLength={100}
                                value={dados.email}
                                onChange={e => {
                                    alterarDados({...dados, email: e.target.value});
                                }}
                            />
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel fontSize={"md"} htmlFor='telefone'>Telefone</FormLabel>
                            <Input
                                type='phone'
                                size="sm"
                                maxLength={100}
                                ref={telCelInput}
                                value={dados.telefone}
                                mask="(11) 11111-1111"
                                as={MaskedInput}
                                onChange={e => {
                                    alterarDados({...dados, telefone: telCelInput.current.mask.getRawValue().split('_').join('')});
                                }}
                            />
                        </FormControl>
                        <FormControl mb={3}>
                            <FormLabel fontSize={"md"} htmlFor='email'>Em que podemos ajudar?</FormLabel>
                            <Textarea 
                                size="sm"
                                maxLength={600}
                                rows={10}
                                value={dados.mensagem}
                                onChange={e => {
                                    alterarDados({...dados, mensagem: e.target.value});
                                }}
                            />
                        </FormControl>
                        <Box textAlign={"right"}>
                            <Button 
                                onClick={enviarMensagem} 
                                isLoading={enviando}
                                isDisabled={validarCampos()}
                            >Enviar!</Button>
                        </Box>
                    </Box>
                </Stack>
            </Center>
        </Container>

        { enviado && 
            <Container 
                maxW='container.lg'
                mt={0}
            >
                <Center my={10} textAlign="center">
                    Sua mensagem foi enviada com sucesso! <br/> Aguarde até que um de nossos colaboradores entre em contato.
                </Center>
            </Container>
        }
    </>

    // função para fazer o envio da mensagem para o pessoal
    async function enviarMensagem(){

        alterarEnviando(true);

        // faz a requisição
        try{

            // registra a mensagem
            let { data } = axios.post(`/sac`, {
                dados: dados
            });

            alterarEnviado(true);

        }catch({e}){}
        finally{
            alterarEnviando(false);
        }
    }

    // valida campos pra deixar enviar
    function validarCampos(){

        // email ou telefone devem ser informado
        if(dados.email.length < 5 && dados.telefone.length < 9){
            return true;
        }

        // deve possuir um nome
        if(dados.nome.length < 5){
            return true;
        }

        // deve possuir uma mensagem
        if(dados.mensagem.length < 5){
            return true;
        }

        // finaliza
        return false;

    }

}