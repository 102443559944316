import React, {
    useState,
    createContext,
    useContext,
    useEffect
} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const PainelContext = createContext({});


// contexto de login
export function PainelProvider({ children }) {

    // estados 
    const [ mensagensDisponiveis, alterarMensagensDisponiveis ] = useState(0);

    
    // atualiza mensagens
    useEffect(() => {
        atualizarMensagensDisponiveis();
    }, []);
    

    return (
        <PainelContext.Provider value={{ mensagensDisponiveis, alterarMensagensDisponiveis }}>
            {children}
        </PainelContext.Provider>
    );

    
    // atualiza a quantidade de mensagens lidas
    async function atualizarMensagensDisponiveis(){
        
        // faz a consulta
        try{

            let { data } = await axios.get(`/sac/informacao`);
            alterarMensagensDisponiveis(parseInt(data.informacao.naoLidos));

        }catch({response}){}

    }
    
}


// finaliza
export function usePainel() {
    const context = useContext(PainelContext);
    return context;
}