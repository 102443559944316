import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex, Spinner, Box, Button, useToast } from "@chakra-ui/react";
import FormularioCadastro from "./FormularioCadastro";

import { usePainelUsuario } from "./../PainelUsuarioContext";
import { intersectObject } from "./../../../Helpers";
import { useLogin } from "./../../Login/LoginContext";


// dados cadastrais do usuário
export default function MeusDados(){

    // estados
    const toast = useToast()
    const login = useLogin();
    const { noturno, maiorQue768, alterarMenuOpcao } = usePainelUsuario();
    const camposSalvar = {
        nome: "",
        nascimento: null,
        rg: "",
        telefoneResidencial: "",
        telefoneCelular: "",
        cep: "",
        endereco: "",
        numero: "",
        cidade: "",
        estado: "AC",
        bairro: "",
        complemento: ""
    };
    const [ dados, alterarDados ] = useState({...camposSalvar});
    const [ carregando, alterarCarregando ] = useState(true);
    const [ erros, alterarErros ] = useState([]);
    const [ salvando, alterarSalvando ] = useState(false);


    useEffect(() => {
        alterarMenuOpcao("");

        // carrega os dados do usuário
        consultarMeusDados();

    }, []);

    // consultar meus dados
    async function consultarMeusDados(){

        try{

            let { data } = await axios.get(`/meuCadastro`);
            alterarDados(data.dados);
            alterarCarregando(false);
        }catch({response}){}
        
    }

    // salvar
    async function salvar(){
        alterarErros([]);
        alterarSalvando(true);

        try{

            // salva
            let dadosSalvar = intersectObject(camposSalvar, dados);
            let { data } = await axios.post(`/meuCadastro`, {dados: dadosSalvar});
            
            // atualiza os dados de login
            login.atualizarDadosUsuario({...login.dados, ...dadosSalvar})

            // mensagem de bem sucedido
            toast({
                title: 'Cadastro atualizado!',
                description: "Seu cadastro foi atualizado com sucesso!",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

        }catch({response}){
            if(response && response.status && response.status === 400){
                alterarErros(response.data);
            }
        }finally{
            alterarSalvando(false);
        }

    }
    

    // se estiver carregando
    if(carregando){
        return <>
            <Flex
                p={10}
                align="center"
                justify="center"
            >
                <Spinner />
            </Flex>
        </>
    }


    return <>
        <FormularioCadastro 
            dados={dados}
            alterarDados={alterarDados}
            erros={erros}
            cpf={false}
        />

        <Box
            mt={6}
            textAlign="center"
        >
            <Button
                onClick={salvar}
                bg={"museu.600"}
                color="white"
                isLoading={salvando}
            >
                Salvar
            </Button>
        </Box>
    </>

}