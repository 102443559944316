
import moment from 'moment';
import * as R from "ramda";

export function capitalizar(texto){
    return texto.toLowerCase().replace(/(^\w|\s\w)/g, m => m.toUpperCase());
}
// verifica se a data é valida
export function validarData(data, tipo = 'valid'){
    if(data !== null && data.length === 10){

        if(tipo === 'valid' && moment(new Date(data)).isValid()){
           return true;
        }else if(!moment(new Date(data)).isValid()){
            return true;
        }
    }
    if(tipo === 'valid'){
        return false;
    }else{
        return false;
    }
}

export function mascaraCpf(valor){
    // eslint-disable-next-line
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,"\$1.\$2.\$3\-\$4");
}

// intersect object
export function intersectObject(obj1, obj2){
    let foo = R.clone(obj1);
    let bar = R.clone(obj2);
    for (var key in bar) {
        if (key in foo)
            foo[key] = bar[key];
    }
    return foo;
}

// converte para reais o valor
export function converterReal(valor){
    // eslint-disable-next-line
    return parseFloat(valor).toFixed(2).replace('.', ',').replace(/(\d)(?=(\d{3})+\,)/g, "$1.");
}
