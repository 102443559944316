import React, { useState, useEffect } from "react";
import { Button, useToast, Flex, Spinner } from "@chakra-ui/react";
import { useComprarPassagem } from "./../../ComprarPassagemContext";
import * as R from "ramda";

// formas de pagamento disponivel
export default function FormasPagamento(){

    const toast = useToast();
    const { metodosPagamento, alterarMetodosPagamento, alterarPagseguroCartoes } = useComprarPassagem();
    const [ carregando, alterarCarregando ] = useState(false);

    // inicializa
    useEffect(() => {
        if(metodosPagamento.length === 0){
            carregarFormasPagamento();
        }
    }, []);
    
    // carrega as formas de pagode
    async function carregarFormasPagamento(){
        alterarCarregando(true);
        window.PagSeguroDirectPayment.getPaymentMethods({
            amount: 200.00,
            success: resposta => {
                
                // se possuir o metodo boleto disponivel, já define
                if(resposta.paymentMethods.CREDIT_CARD){
                    
                    // formas pagamento
                    alterarMetodosPagamento(resposta.paymentMethods);
                    alterarPagseguroCartoes(resposta.paymentMethods.CREDIT_CARD.options);
                }

            },
            error: resposta => {
                
                // mensagem de bem sucedido
                toast({
                    title: 'Tente mais tarde!',
                    description: "Não conseguimos nos comunicar com o pagseguro",
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            },
            complete: () => {
                alterarCarregando(false);
            }
        });
    }


    // se estiver carregando
    if(carregando){
        return <>
            <Flex
                p={10}
                align="center"
                justify="center"
            >
                <Spinner />
            </Flex>
        </>
    }

    return <>

    </>

}