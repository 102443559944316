import React, { useState } from "react";
import axios from "axios";
import { Box, Flex, FormControl, Container, Button, Input, FormHelperText, Link, useToast, useBoolean } from "@chakra-ui/react";
import { useNavigate, useLocation } from "react-router-dom";
import useIsMounted from 'ismounted';

// pagina de login do usuário
export default function TrocarSenha(){

    // estados
    const { search } = useLocation();
    const query = new URLSearchParams(search);
    const isMounted = useIsMounted();
    const toast = useToast()
    const [ dados, alterarDados ] = useState({
        email: query.get("email"),
        token: query.get("token"),
        senha: ""
    });
    const [ erros, alterarErros ] = useState({});
    const navigate = useNavigate();
    const [ salvando, alterarSalvando ] = useState(false);

    // autenticando
    async function salvar(){
        alterarSalvando(true);

        // faz a requisição e autentica
        try{
            let { data } = await axios.post(`/recuperarSenha/confirma`, dados);
            

            toast({
                title: 'Senha salva com sucesso!',
                description: "Basta fazer login com sua nova senha, obrigado!",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            // após autenticado, redireciona para página de painel de usuário
            navigate("/login");

        }catch({response}){
            if(response && response.status && response.status === 400){
                alterarErros(response.data);
                toast({
                    title: 'Verifique as informações novamente!',
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }finally{
            if (isMounted.current) {
                alterarSalvando(false);
            }
        }
    }

    return <>
        <Container
            minHeight={"70vh"}
        >
            <Flex justify="center" align="flex-start">
                <Box
                    bg="whiteAlpha.200"
                    rounded={3}
                    p={10}
                    mt={20}
                    mb={20}
                    w={"100%"}
                >
                    <FormControl>
                        <Input 
                            isInvalid={erros.senha ? true : false}
                            mt={4}
                            placeholder="Sua nova senha"
                            onChange={(e) => {
                                alterarDados({...dados, senha: e.target.value});
                            }}
                            variant='flushed'
                            type="password"
                            focusBorderColor="museu.600"
                        />
                        {erros.senha && <FormHelperText color="red.600">{erros.senha}</FormHelperText>}
                        {erros.email && <FormHelperText color="red.600">{erros.email}</FormHelperText>}

                        <Box textAlign={"right"} mt={4}>
                            <Button isLoading={salvando} onClick={salvar} colorScheme={"green"}>Salvar</Button>
                        </Box>
                    </FormControl>
                </Box>
            </Flex>
        </Container>
    </>


}