
import { extendTheme } from "@chakra-ui/react";
import { mode } from '@chakra-ui/theme-tools'
// console.log(mode("yellow.600","museu.900"));
const customTheme = extendTheme({
    colors: {
        museu: {
            50: "#ff6363",
            100: "#e84a4a",
            150: "#e82827",
            200: "#de1c1b",
            300: "#c90f0e",
            400: "#b80504",
            500: "#a80302",
            600: "#990100", // original
            700: "#8a0100",
            800: "#7d0100",
            900: "#6b0100"
        },
    },
    components: {
        Button: {
            baseStyle: {
                _focus: {
                    boxShadow: "none"
                }
            }
        },
        Text: {
            variants: {
                linkMenu: (props) => ({
                    borderBottomWidth: 1,
                    borderBottomColor: "transparent", 

                    _hover: {
                        color: mode("museu.900", "gray.100")(props),
                        borderBottomColor: mode("museu.900", "gray.100")(props), 
                        borderBottomWidth: 1,
                        cursor: "pointer"
                    }
                })
            }
        }
    }

});

export default customTheme;


// // theme.js
// export default tema = {
//     colors: {
//         transparent: 'transparent',
//         black: '#000',
//         white: '#fff',
//         gray: {
//             50: '#f7fafc',
//             // ...
//             900: '#171923',
//         },
//         // ...
//     },
// }