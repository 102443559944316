import React, { useEffect, useState } from "react";
import axios from "axios";
import * as R from "ramda"

import { Box, Input, Container, Flex, Image, Heading, Divider, Button, useToast, Radio, RadioGroup, Stack } from "@chakra-ui/react";
import { useComprarPassagem } from "./../../ComprarPassagemContext";
import useIsMounted from 'ismounted';

export default function CartaoDebito(){

    const isMounted = useIsMounted();
    const toast = useToast();
    const { maiorQue1024, dados, noturno, metodosPagamento, pagamento, alterarPagamento, listaPassageiros, processandoPagamento, alterarProcessandoPagamento, alterarErrosPassageiros, alterarErrosPagamento, alterarEtapa } = useComprarPassagem();
    const [ cvvSize, alterarCvvSize ] = useState(20);
    

    // finalizar pagamento
    async function processarPagamento(){
        alterarProcessandoPagamento(true);
        alterarErrosPassageiros([]); 
        alterarErrosPagamento([]);

        // pega a hash
        let dadosPagamento = null;
        if(pagamento.hash === null){
            let hash = null;
            try{
                hash = await new Promise((finalizado, rejeitado) => {
                    window.PagSeguroDirectPayment.onSenderHashReady(retorno => {
                        if(retorno.status === 'error') {
                            rejeitado();
                        }
                        finalizado(retorno.senderHash);
                    });
                });
            }catch(error){
                alterarProcessandoPagamento(false);
                return false;
            }

            // inclui a hash
            dadosPagamento = R.mergeDeepRight(pagamento, { hash: hash });
            alterarPagamento(dadosPagamento);
        }else{
            dadosPagamento = R.clone(pagamento);
        }
        
        // faz a requisição para a compra
        try{

            let { data } = await axios.post(`/transacao/finalizar/debitoOnline`, {
                idEvento: dados.id,
                dados: dadosPagamento,
                passageiros: listaPassageiros
            });

            // redirecionar para tela de venda finalizada
            alterarEtapa("finalizada");

            // Venda finalizada
            toast({
                title: 'Parabéns! Sua compra foi efetuada com sucesso!',
                description: "Você receberá um email assim que seu pagamento for aprovado pelo Pagseguro",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            window.open(data.link, "_blank");
            
        }catch({response}){
            console.log(response.data);
            if(response && response.status && response.status === 400){

                // passeio indisponivel
                if(response.data.passeioIndisponivel){
                    toast({
                        title: 'Passeio indisponivel para vendas!',
                        // description: "Esse passeio já não está mais disponivel para vendas",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if(response.data.passageiros){
                    alterarErrosPassageiros(response.data.passageiros);
                    alterarEtapa(maiorQue1024 ? "vagas" : "passageiros");
                    toast({
                        title: 'Verifique as informações dos passageiros!',
                        // description: "Esse passeio já não está mais disponivel para vendas",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                if(response.data.pagamento){
                    alterarErrosPagamento(response.data.pagamento);
                    toast({
                        title: 'Verifique os dados pessoais!',
                        // description: "Esse passeio já não está mais disponivel para vendas",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }
        }finally{
            alterarProcessandoPagamento(false);
        }
    }


    return <>
        <Container
            bg={noturno ? "gray.900" : "gray.100"}
            p={5}
            mb={5}
            rounded={5}
            mx="auto"
        >
            
            <Flex align={"center"} justify={"space-between"}>
                <Heading size="sm">Débito Online</Heading>
            </Flex>
            <Divider my={3} />

            <Box mb={5}>
                <RadioGroup defaultValue={pagamento.bancoNome}>
                    <Stack>
                        {Object.entries(metodosPagamento["ONLINE_DEBIT"].options).map(bancoNome => 
                            <Radio 
                                key={bancoNome[0]}
                                colorScheme='green'
                                isChecked={pagamento.bancoNome === bancoNome[0]}
                                value={bancoNome[0]}
                                onChange={e => {
                                    let dadosPagamento = R.mergeDeepRight(pagamento, { bancoNome: bancoNome[0] });
                                    alterarPagamento(dadosPagamento);
                                }}
                            >
                                {bancoNome[1].displayName}
                            </Radio>    
                        )}
                    </Stack>
                </RadioGroup>
            </Box>
            
            <Box textAlign={"right"}>
                <Button
                    variant={"solid"}
                    colorScheme={"green"}
                    isDisabled={pagamento.bancoNome === null}
                    onClick={processarPagamento}
                    isLoading={processandoPagamento}
                >
                    Processar Pagamento
                </Button>
            </Box>
        </Container>
    </>

}