import React from "react";

// componentes
import { useColorModeValue, useMediaQuery, Box, Container, Grid, Image, Heading, Button, Link, Flex, Center, Divider } from "@chakra-ui/react";
import logo1 from "./../../Assets/logo1.png";
import logo2 from "./../../Assets/logo2.png";
import { FaCopyright, FaEnvelope, FaFacebook, FaInstagram, FaYoutube } from "react-icons/fa";

export default function Rodape(){

    const [ maiorQue1024 ] = useMediaQuery('(min-width: 1024px)');
    const [ menorQue768 ] = useMediaQuery('(max-width: 767px)');
    const noturno = useColorModeValue(false, true);

    return <>
        <Box
            bg={"museu.600"}
            p={10}
            color={"gray.200"}
        >
            <Container
                maxW='container.xl'
            >
                <Grid templateColumns={`repeat(${maiorQue1024 ? 4 : menorQue768 ? 1 : 2}, 1fr)`} gap={6}>
                    <Box>
                        <Heading mb={2} size={"xs"}>Museu Ferroviário Tubarão</Heading>
                        <Box>
                            <Link borderLeftWidth={3} pl={2} mb={2} display={"block"} fontSize={"md"}>Museu</Link>
                            <Link borderLeftWidth={3} pl={2} mb={2} display={"block"} fontSize={"md"}>Acervos</Link>
                            <Link borderLeftWidth={3} pl={2} mb={2} display={"block"} fontSize={"md"}>Sac</Link>
                            <Flex borderLeftWidth={3} pl={2}  as={Link} mb={2} target="_blank" href="mailto:setorcomercialmft@gmail.com">
                                <FaEnvelope style={{marginRight: 4, marginTop: 4}} /> Contato Email
                            </Flex>
                        </Box>
                    </Box>
                    <Box>
                        <Heading mb={2} size={"xs"}>Passeio Turístico</Heading>
                        <Box>
                            <Link borderLeftWidth={3} pl={2} mb={2} display={"block"} fontSize={"md"}>Escolha seu passeio</Link>
                            <Link borderLeftWidth={3} pl={2} mb={2} display={"block"} fontSize={"md"}>Compre seu ingresso</Link>
                        </Box>
                    </Box>
                    <Box>
                        <Heading mb={2} size={"xs"}>Redes sociais</Heading>
                        <Flex borderLeftWidth={3} pl={2} as={Link} mb={2} target="_blank" href="https://www.facebook.com/museuferroviario/">
                            <FaFacebook style={{marginRight: 4, marginTop: 4}} /> Museu Ferroviário
                        </Flex>
                        {/* <Flex borderLeftWidth={3} pl={2}  as={Link} mb={2} target="_blank" href="https://www.facebook.com/passeioferroviatiomft/">
                            <FaFacebook style={{marginRight: 4, marginTop: 4}} /> Passeio Ferroviário
                        </Flex> */}
                        {/* <Flex borderLeftWidth={3} pl={2}  as={Link} mb={2} target="_blank" href="https://www.facebook.com/museuferroviariotb/">
                            <FaFacebook style={{marginRight: 4, marginTop: 4}} /> Passeio Ferroviário Tubarão
                        </Flex> */}
                        <Flex borderLeftWidth={3} pl={2}  as={Link} mb={2} target="_blank" href="https://www.instagram.com/passeioferroviarioexpresso/">
                            <FaInstagram style={{marginRight: 4, marginTop: 4}} /> Museu Ferroviário Tubarão
                        </Flex>
                        {/* <Flex borderLeftWidth={3} pl={2}  as={Link} mb={2} target="_blank" href="https://www.instagram.com/passeioferroviario">
                            <FaInstagram style={{marginRight: 4, marginTop: 4}} /> Passeio Ferroviário
                        </Flex> */}
                        {/* <Flex borderLeftWidth={3} pl={2}  as={Link} mb={2} target="_blank" href="https://www.youtube.com/channel/UCSZYpLsH5ob70qxyAMoL9ow">
                            <FaYoutube style={{marginRight: 4, marginTop: 4}} /> Passeio Ferroviário
                        </Flex> */}

                        
                    </Box>
                    <Center textAlign={"center"}>
                        <Image display={"inline"} maxW={"150px"} src={logo2} />
                    </Center>
                </Grid>
            </Container>
            <Divider mt={10} mb={3} borderColor={"whiteAlpha.300"} />
            <Container
                maxW='container.xl'
                fontSize={"xs"}
            >
                <Flex justify={"space-between"}>
                    <Box>
                        Todos os direitos reservados para o Museu Ferroviário de Tubarão
                    </Box>
                    <Box>
                        <Link target={"_blank"} href="www.rubipropaganda.com.br"><FaCopyright style={{display:"inline"}} /> Por Rubi Design Digital</Link>
                    </Box>
                </Flex>
                
            </Container>
        </Box>
    </>

}