import React from "react";
import { Alert, Box, Text } from "@chakra-ui/react";
import { BsFillPlayFill } from "react-icons/bs";
import moment from "moment";
import { AiOutlineRollback } from "react-icons/ai";
import { IoIosArrowRoundForward } from "react-icons/io";
import { IoTicketSharp } from "react-icons/io5";

export default function PasseioPartidaRetorno({
    dados
}){


    return <>
        {dados.saidaPartida &&
            <Alert status='success' fontSize={"xs"} p={2} rounded={1} alignItems="start">
                {/* <AlertIcon /> */}
                <Box display={"inline"} rounded={"100%"} bg={"green.500"} p={1} mr={2} fontSize={"md"}>
                    <IoIosArrowRoundForward style={{color: "white"}} />
                </Box>
                <Box>
                    <Text fontWeight={"bold"} fontSize="sm">Partida</Text>
                    <Box>Saída: {moment(dados.saidaPartida).format("HH:mm[h] [em] DD [de] MMMM")}</Box>
                    {dados.saidaChegada && 
                        <Box>Chegada: {moment(dados.saidaChegada).format("HH:mm[h] [em] DD [de] MMMM")}</Box>
                    }
                </Box>
            </Alert>
        }

        {dados.retornoPartida &&
            <Alert status='success' fontSize={"xs"} p={2} rounded={1} alignItems="start">
                <Box display={"inline"} rounded={"100%"} bg={"orange.400"} p={1} mr={2} fontSize={"md"}>
                    <AiOutlineRollback style={{color: "white"}} />
                </Box>
                <Box>
                    <Text fontWeight={"bold"} fontSize="sm">Retorno</Text>
                    <Box>Saída: {moment(dados.retornoPartida).format("HH:mm[h] [em] DD [de] MMMM")}</Box>
                    {dados.retornoChegada && 
                        <Box>Chegada: {moment(dados.retornoChegada).format("HH:mm[h] [em] DD [de] MMMM")}</Box>
                    }
                </Box>
            </Alert>
        }


        {dados.linkMinhaentrada && 
            <a href={dados.linkMinhaentrada} display="inline-block" style={{width: "100%"}} target="_blank">
                <Alert status='info' fontSize={"xs"} p={2} rounded={1} >
                    <Box display={"inline"} rounded={"100%"} bg={"blue.400"} p={1} mr={2} fontSize={"md"}>
                        <IoTicketSharp style={{color: "white"}} />
                    </Box>
                    <Box>
                        <Text fontWeight={"bold"} fontSize="sm" textAlign="center">Comprar passagens</Text>
                        {/* <Box>Saída: {moment(dados.retornoPartida).format("HH:mm[h] [em] DD [de] MMMM")}</Box>
                        {dados.retornoChegada && 
                            <Box>Chegada: {moment(dados.retornoChegada).format("HH:mm[h] [em] DD [de] MMMM")}</Box>
                        } */}
                    </Box>
                </Alert>
            </a>
        }
    </>

}