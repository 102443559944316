import React, {
    useState,
    createContext,
    useContext,
    useEffect
} from 'react';
import { useNavigate } from "react-router-dom";
import axios from "axios";

const LoginContext = createContext({});

// contexto de login
export function LoginProvider({ children }) {

    // define os estados
    const [ autenticado, alterarAutenticado ] = useState(false);
    const [ dados, alterarDados ] = useState(null);
    const [ verificando, alterarVerificando ] = useState(true);
    const navigate = useNavigate();

    // se o usuário já está online, faz uma verificação
    useEffect(() => {

        // verifica se possui um login já salvo
        let loginSalvo = JSON.parse(window.localStorage.getItem('loginMuseu'));
        let tokenSalvo = window.localStorage.getItem("tokenMuseu");

        // se possuir token, configura
        if(tokenSalvo){
            configurarToken(tokenSalvo);
        }

        // se possuir login, entra
        if(loginSalvo){
            alterarDados(loginSalvo);
            alterarAutenticado(true);
        }

        // terminou de verificar o localStorage
        alterarVerificando(false);
        
    }, []);

    // atualiza somente os dados
    function atualizarDadosUsuario(dadosNovos){

        alterarDados(dadosNovos);
        window.localStorage.setItem("loginMuseu", JSON.stringify(dadosNovos));
    }

    // atualiza os dados
    function atualizar(dadosNovos, token){

        // registra
        atualizarDadosUsuario(dadosNovos);
        configurarToken(token);
        alterarAutenticado(dadosNovos === null ? false : true);

        // salva no localStorage
        window.localStorage.setItem("tokenMuseu", token);

    }

    // configura axios
    function configurarToken(token = null){

        // configura axios
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }

    // desloga o usuáiro
    function sair(){
        alterarAutenticado(false);
        window.localStorage.removeItem("loginMuseu");
        window.localStorage.removeItem("tokenMuseu");
        axios.defaults.headers.common['Authorization'] = null;
        alterarDados(null);
        navigate("/");
    }


    return (
        <LoginContext.Provider value={{ autenticado, dados, atualizar, atualizarDadosUsuario, sair }}>
            {!verificando && children}
        </LoginContext.Provider>
    );

}

// finaliza
export function useLogin() {
    const context = useContext(LoginContext);
    return context;
}