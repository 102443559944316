import React, { useEffect, useState } from "react";
import axios from "axios";
import { Image, Box, Button, Link, Container, Center, Flex, Text } from "@chakra-ui/react";
import Carousel from 'nuka-carousel';
import Eventos from "./../Eventos";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function Banners(){

    const navigate = useNavigate();
    const [carregando, alterarCarregando] = useState(true);
    const [registros, alterarRegistros] = useState([]);

    // atualiza
    useEffect(() => {
        carregar();
    }, []);

    async function carregar(){

        try{

            //faz a requisição
            let { data } = await axios.get(`/banners`, { params : { mostrar: "S" }});
            alterarRegistros(data.registros);

        }catch({response}){}

    }

    return <>
    
        <Carousel
            autoplay
            speed={2000}
            easing={"easePoly"}
            wrapAround={true}
            withoutControls={true}
            defaultControlsConfig={{
                nextButtonText: "Avançar",
                prevButtonText: "Voltar",
                pagingDotsStyle: {
                    fill: 'orange'
                }
            }}
            width="100%"
        >
            {registros.map(registro => 
                <Image 
                    key={registro.dados.id}
                    alt='' 
                    src={`${process.env.REACT_APP_URL_ARQUIVOS}/anexos/${registro.arquivo.temporario}`}
                />
            )}
        </Carousel>

        <Eventos  
            limitado 
            faixa={false}
        />

        <Container
            maxW='container.xl'
        >
            <Box my={20} textAlign={"right"}>
                <Flex as={Link} justify={"end"} align={"center"} onClick={() => navigate(`/passeios`)}>
                    <Text mr={2}>Ver mais</Text> <FaLongArrowAltRight style={{display:"inline"}} />
                </Flex>
            </Box>
        </Container>
        
    </>


}