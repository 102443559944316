import React from 'react';
import MaskedInput from 'react-maskedinput';
import * as R from "ramda";

// funções
import { validarData } from "./../../Helpers";


// componentes
// import InputMask from "react-input-mask";
import { Input, FormHelperText } from "@chakra-ui/react";

function InputData({
    value,
    onChange,
    className='',
    showHelper=true,
    ...props
}){
    let dataRef = React.createRef();

    return <>
        <Input 
            textAlign="right"
            as={MaskedInput}
            placeholder='Data Nascimento*'
            mask="11/11/1111"
            ref={dataRef}
            value={(value === null)? '' : value.split('-').reverse().join('/')} 
            onChange={(e) => {
                value = e.target.value.split('_').join('').split('/').reverse().join('-');
                if(e.target.value === ''){
                    value = null;
                }
                
                // modificado a data
                onChange({
                    target: {
                        value,
                        name: props.name ?? undefined
                    }
                });
            }}
            isInvalid={validarData(value, 'invalid')}
            {...props}
        />
        { (showHelper && validarData(value, 'invalid')) &&
            <FormHelperText color="red">Data inválida.</FormHelperText>
        }
    </>
    // <Form.Control.Feedback type="invalid">Data inválida</Form.Control.Feedback>
}

// retorna
export default InputData; 