import React from "react";
import { Box, Button, Input, Flex } from "@chakra-ui/react";

import { HiCheckCircle, HiUser } from "react-icons/hi";
import { useTremCarro } from "./../TremCarroContext";
import { icones } from "./icones";

export default function Vagas(){

    // estados
    const { noturno, dadosCarro, dadosVagas, alterouVaga, dimensao, focarVaga, clicouVaga, vagasSelecionadas, vagasConfirmadas, modo, vagasEsconder, vendasVagaSite } = useTremCarro();
    
    // define o icone, cor e tipo
    function definirIcone(vaga){

        // se o modo for venda, reserva fica como ocupada
        let setIcones = icones[vaga.desenho.id];
        
        // define qual a cor
        let icone = setIcones.livre;

        // vagas selecionadas
        if(vagasSelecionadas.includes(vaga.id)){
            icone = setIcones.focada;
        }

        // ocupada - amarela
        if(vaga.ocupada === "S" && vaga.ocupadaPor === "reserva"){
            icone = setIcones.reserva;

            // se for modo cliente, é a mesma cor de ocupado
            if(modo === "vendedor" && vagasSelecionadas.includes(vaga.id)){
                icone = setIcones.focada;
            }

            // se for modo cliente, é a mesma cor de ocupado
            if(modo === "cliente"){
                icone = setIcones.ocupada;
            }
        }

        // ocupada
        if(vaga.ocupada === "S" && vaga.ocupadaPor === "venda"){
            icone = setIcones.ocupada;
        }
        
        // retorna icone
        return icone;

    }

    return <>
        {dadosVagas.map((vaga, i) => {

            let icone = definirIcone(vaga);
            let esconder = vagasEsconder && vaga.id && vagasEsconder.includes(vaga.id);

            return <Box
                key={vaga._tempId || vaga.id}
                as="g"
                transform={`translate(${vaga.desenho.x * dimensao.bloco.w}px,${vaga.desenho.y * dimensao.bloco.h}px)`}
                // opacity={vaga._excluir ? 0.5 : 1}
                // style={{display: esconder ? "none" : "inline"}}
                opacity={esconder ? 0.2 : 1}
            >
                <Box 
                    as="image"
                    x="0" 
                    y="0"
                    style={{ transformBox: "fill-box" }}
                    transformOrigin="center"
                    transform={`rotate(${vaga.desenho.rotacao}deg)`}
                    cursor="pointer"
                    onClick={() => {
                        clicouVaga(vaga);
                    }}

                    // customizar
                    h={`${2 * dimensao.bloco.h}px`}
                    w={`${2 * dimensao.bloco.w}px`}
                    xlinkHref={icone}
                    
                />

                {/* se for admin, habilita edição de código */}
                <Box
                    as="foreignObject"
                    h="60px" 
                    w="60px" 
                    pointerEvents="none"
                >
                    <Flex 
                        align="center" 
                        justify="center" 
                        h="100%"
                        pointerEvents="none"
                    >
                        <Input 
                            pointerEvents={modo === "admin" ? "initial" : "none"}
                            textShadow={"0 0 6px #000"}
                            bg="none"
                            _focus={{bg: "white", color: "black", display: "inline", _autofill: true}}
                            border="none"
                            color="white"
                            p={0}
                            h="auto"
                            w={25}
                            textAlign="center"
                            fontSize="xs"
                            fontWeight="bold"
                            disabled={modo !== "admin" || (vaga.ocupada === "S")}
                            value={vaga.codigo}
                            _disabled={{
                                color:"white"
                            }}
                            onChange={e => {
                                alterouVaga({...vaga, codigo: e.target.value}, {...vaga});
                            }}
                            
                        />
                    </Flex>
                </Box>
            </Box>    
        })}

        {/* destaca vaga */}
        <>
            {dadosVagas.filter((vaga, i) => (focarVaga === vaga.id || focarVaga === vaga._tempId) ).map(vaga => 
                <Box
                    key={vaga._tempId || vaga.id}
                    as="rect" 
                    stroke="green.300" 
                    fill="transparent" 
                    strokeWidth={3}
                    h="60px" 
                    w="60px" 
                    rx={"2"}
                    pointerEvents="none"
                    transform={`translate(${vaga.desenho.x * dimensao.bloco.w}px,${vaga.desenho.y * dimensao.bloco.h}px)`}
                />
            )}
        </>

        <>
            {dadosVagas.filter((vaga, i) => vagasConfirmadas.includes(vaga.id) ).map(vaga => 
                <Box
                    key={vaga.id}
                    as="foreignObject"
                    h="20px" 
                    w="20px" 
                    pointerEvents="none"
                    transform={`translate(${(vaga.desenho.x * dimensao.bloco.w)}px,${(vaga.desenho.y * dimensao.bloco.h)}px)`}
                >
                    <Box bg={"white"} display={"inline-block"} rounded={20}>
                        <HiCheckCircle color="#4aaf18" size={15} />
                    </Box>
                    
                </Box>
            )}
        </>

        <>
            {dadosVagas.filter((vaga, i) => vendasVagaSite.includes(vaga.id) ).map(vaga => 
                <Box
                    key={vaga.id}
                    as="foreignObject"
                    h="20px" 
                    w="20px" 
                    pointerEvents="none"
                    transform={`translate(${(vaga.desenho.x * dimensao.bloco.w) + 15}px,${(vaga.desenho.y * dimensao.bloco.h)}px)`}
                >
                    <Box bg={"white"} display={"inline-block"} rounded={20}>
                        <HiUser color="#4aaf18" size={15} />
                    </Box>
                </Box>
            )}
        </>

    </>
    
}