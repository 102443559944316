import React, { useEffect, useState } from "react";
import { useComprarPassagem } from "./../../../ComprarPassagemContext";
import * as R from "ramda";
import { Box, Table, Thead, Th, Tr, Tbody, Td, Radio, Text, Flex, Spinner } from "@chakra-ui/react";
import { converterReal } from "./../../../../../Helpers";

export default function Parcelamento(){

    const { noturno, pagamento, alterarPagamento } = useComprarPassagem();
    const [ parcelas, alterarParcelas ] = useState([]);
    const [ carregando, alterarCarregando ] = useState(false);

    // inicializa
    useEffect(() => {
        carregarParcelasDisponiveis();
    }, [])

    // carrega
    function carregarParcelasDisponiveis(){
        alterarCarregando(true);

        // quantidade sem juros
        let quantidadeParcelamentoSemJuros = quantidadeSemJuros();

        // pega os dados de parcelamento
        window.PagSeguroDirectPayment.getInstallments({
            amount: pagamento.totalValor,
            maxInstallmentNoInterest: quantidadeParcelamentoSemJuros,
            brand: pagamento.bandeiraNome,
            success: ({ installments }) => {
                let bandeira = pagamento.bandeiraNome;
                let posParcela = pagamento.quantidadeParcelas - 1;
                
                // pré define o valor da parcela
                let dadosPagamento = null;
                if(installments[bandeira][posParcela]){
                    
                    // se ainda existe a parcela depois de alterado, mantém
                    dadosPagamento = R.mergeDeepRight(pagamento, {
                        valorParcelas: installments[bandeira][posParcela].installmentAmount,
                    });
                }else{

                    // define para a primeira parcela
                    dadosPagamento = R.mergeDeepRight(pagamento, {
                        quantidadeParcelas: 1,
                        valorParcelas: installments[bandeira][0].installmentAmount
                    });
                }
                alterarPagamento(dadosPagamento);
                alterarParcelas(installments[bandeira]);
           },
            error: function(response) {},
            complete: function(response){
                alterarCarregando(false);
            }
        });

    }

    // define quantidade de parcelas que será sem juros
    function quantidadeSemJuros(){

        // quantidade de parcelamento viável
        let quantidadeParcelamento = undefined;
        if(pagamento.totalValor > 600){
            quantidadeParcelamento = 6;
        }else if(pagamento.totalValor > 500){
            quantidadeParcelamento = 5;
        }else if(pagamento.totalValor > 400){
            quantidadeParcelamento = 4;
        }else if(pagamento.totalValor > 300){
            quantidadeParcelamento = 3;
        }else if(pagamento.totalValor > 200){
            quantidadeParcelamento = 2;
        }
        return quantidadeParcelamento;
    }

    // se estiver carregando
    if(carregando){
        return <>
            <Flex
                p={10}
                align="center"
                justify="center"
            >
                <Spinner />
            </Flex>
        </>
    }

    return <>
        {/* parcelamento */}
        <Box
            bg={noturno ? "gray.900" : "gray.50"}
            rounded={5}
        >
            <Table size="sm">
                <Thead>
                    <Tr>
                        <Th></Th>
                        <Th textAlign={"center"}>Parcela</Th>
                        <Th textAlign={"center"}>Total</Th>
                    </Tr>
                </Thead>
                <Tbody>
                    {parcelas.map(parcela => 
                        <Tr 
                            key={parcela.quantity} 
                            _hover={{ Td: {bg: noturno ? "whiteAlpha.300" : "gray.200"}}}
                            onClick={() => {
                                let novoPagamento = R.mergeDeepRight(pagamento, { quantidadeParcelas: parcela.quantity, valorParcelas: parcela.installmentAmount });
                                alterarPagamento(novoPagamento);
                            }}
                            cursor={"pointer"}
                        >
                            <Td w="1%" px={10}>
                                <Radio 
                                    isChecked={parcela.quantity === pagamento.quantidadeParcelas} 
                                    onChange={e => {
                                        let novoPagamento = R.mergeDeepRight(pagamento, { quantidadeParcelas: parcela.quantity, valorParcelas: parcela.installmentAmount });
                                        alterarPagamento(novoPagamento);
                                    }} />
                            </Td>
                            <Td  
                                px={10}
                                textAlign="center"
                                whiteSpace="nowrap"
                            >
                                {parcela.quantity} 
                                <Text display={"inline"} mx={2}>x</Text> 
                                {converterReal(parcela.installmentAmount)} R$
                                {(quantidadeSemJuros() && parseInt(parcela.quantity) <= quantidadeSemJuros()) && <Text color="green.500" fontWeight={"bold"}>s/ juros</Text>}
                            </Td>
                            <Td  px={10} textAlign={"center"} whiteSpace={"nowrap"}>{converterReal(parcela.totalAmount)} R$</Td>
                        </Tr>    
                    )}
                </Tbody>
            </Table>
        </Box>
    </>
}