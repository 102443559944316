import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Flex, Text, HStack, Image, Button, Tooltip, Badge, InputGroup, InputLeftElement, Input, InputRightAddon, InputRightElement, Divider, Center, Spinner, Heading } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { MdOutlineTag } from "react-icons/md";
import moment from "moment";
import { FaBaby, FaBabyCarriage, FaCalendarDay, FaPlus, FaUserFriends } from "react-icons/fa";
import { converterReal } from "../../../Helpers";
import { usePainelUsuario } from "../PainelUsuarioContext";

export default function Pedidos(){

    // estados
    const navigate = useNavigate();
    const [ parametros, alterarParametros ] = useState({
        pagina: 1,
        registrosPorPagina: 5
    });
    const [ registros, alterarRegistros ] = useState([]);
    const [totalRegistros, alterarTotalRegistros] = useState(0);
    const { noturno, maiorQue768, maiorQue1024 } = usePainelUsuario();
    const [ carregando, alterarCarregando ] = useState(true);
    
    useEffect(() => {
        consultarRegistros();
    }, [ parametros ]); // eslint-disable-next-line react-hooks/exhaustive-deps

    const BadgeInfo = ({ icone, children, ...props }) => <>
        <Flex 
            bg={"whiteAlpha.500"} 
            rounded={3} px={1} 
            whiteSpace={"nowrap"} 
            align={"center"} 
            fontSize={"xs"} 
            fontWeight={"normal"}
            {...props}
        >
            {icone}<Text ml={1}>{children}</Text>
        </Flex>
    </>

    const BadgeStatus = ({status}) => {
        
        return <BadgeInfo 
            color="white" 
            bg={verificarStatus(status).cor} 
            icone={<></>}
            display={"inline-flex"}
        >
            {verificarStatus(status).nome}
        </BadgeInfo>
    }

    return <>
        <Heading mb={4} size={"md"}>Meus pedidos</Heading>

        {(!carregando && totalRegistros === 0) &&
            <Center minH={"100px"}>
                Você ainda não possui nenhuma pedido!
            </Center>
        }

        {registros.map(registro => 
            <Box
                key={registro.dados.id}
                bg={noturno ? "gray.800" : "gray.200"}
                cursor={"pointer"}
                rounded={3}
                p={2}
                _notLast={{
                    mb: 2
                }}
                _hover={{
                    bg: noturno ? "gray.900" : "gray.300"
                }}
                onClick={() => {
                    navigate(registro.dados.id);
                }}
            >
                <Flex gridGap={3}>
                    <Box
                    
                    >
                        {registro.card && 
                            <Image 
                                h="auto" 
                                verticalAlign={"top"}
                                w="50px" 
                                src={`${process.env.REACT_APP_URL_ARQUIVOS}/anexos/${registro.card.temporario}`}
                                rounded={3}
                            />
                        }
                    </Box>
                    <Box
                        flex={1}
                    >
                        <Flex
                            justify={"space-between"}
                            align={"center"}
                            mb={1}
                        >
                            <HStack justifyContent={"start"} flex={1} align={"center"}>
                                
                                <Flex gridGap={1}>
                                    <Text fontSize={"xs"}>Pedido</Text>
                                    <BadgeInfo icone={<MdOutlineTag />}>{registro.dados.id}</BadgeInfo>
                                </Flex>
                                

                                {maiorQue1024 && <>
                                    <BadgeInfo icone={<FaUserFriends />}>{registro.passageiros.length}</BadgeInfo>
                                    {registro.passageiros.filter(p => p.crianca).length > 0 &&
                                        <BadgeInfo icone={<FaBabyCarriage />}>{registro.passageiros.filter(p => p.crianca).length}</BadgeInfo>
                                    }
                                </>}
                            </HStack>
                            <HStack flex={1} justifyContent={"end"}>
                                <BadgeInfo icone={<FaCalendarDay />}>{moment(registro.evento.saidaPartida).format(`DD/MM/YYYY`)}</BadgeInfo>
                                {maiorQue1024 && 
                                    <BadgeInfo icone={<small>R$</small>}>{ converterReal(registro.transacao.total)}</BadgeInfo>
                                }
                                {maiorQue768 && 
                                    <BadgeStatus status={registro.transacao.status} />
                                }
                            </HStack>
                        </Flex>
                        <Box>
                            <Text fontSize={"sm"}>{registro.evento.titulo}</Text>
                        </Box>

                        {!maiorQue768 && 
                            <BadgeStatus status={registro.transacao.status} />
                        }
                    </Box>
                </Flex>
            </Box>
        )}

        {registros.length === 0 && carregando && 
            <Center>
                <Spinner size="lg" />
            </Center>
        }

        <Box textAlign={"right"}>
            { registros.length < totalRegistros && 
                <Button
                    onClick={e => {
                        alterarParametros({
                            ...parametros,
                            pagina: parametros.pagina + 1
                        });
                    }}
                    size={"xs"}
                    isLoading={carregando}
                >
                    Carregar Mais
                </Button>
            }
        </Box>
    </>

    // consulta os registros
    async function consultarRegistros(){
        alterarCarregando(true);
        try{

            let { data } = await axios.get(`/pedidos`, {params: parametros});
            alterarRegistros([...registros, ...data.registros]);
            alterarTotalRegistros(parseInt(data.totalRegistros));

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }

    }

    // retorna informações sobre o pedido
    function verificarStatus(status){

        // identifica o status da transação
        let statusInfo = { nome: "Aguardando", cor: "gray" };
        switch(status){
            case "aprovado": 
                statusInfo.nome = "Aprovado";
                statusInfo.cor = "green"; 
            break;
            case "disponivel": 
                statusInfo.nome = "Aprovado";
                statusInfo.cor = "green"; 
            break
            case "disputa": 
                statusInfo.nome = "Disputa";
                statusInfo.cor = "red"; 
            break;
            case "cancelada": case "devolvida": 
                statusInfo.nome = "Cancelado";
                statusInfo.cor = "red"; 
            break;
            default:
        }

        // retorna as informações de status
        return statusInfo;
    }
}