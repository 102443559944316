import React from "react";
import { useComprarPassagem } from "../../ComprarPassagemContext";
import { Box, Flex, Text, Container, Divider } from "@chakra-ui/react";
import { converterReal } from "./../../../../Helpers";

// cria relatório de passagens
export default function RelatorioPassagens(){

    // estados
    const { noturno, maiorQue1024, pagamento, passagens, listaPassageiros } = useComprarPassagem();

    return <>
    
        {/* relatórios de passageiros */}
        <Container
            bg={noturno ? "gray.900" : "gray.100"}
            p={5}
            mb={5}
            rounded={5}
            mx="auto"
        >
            {listaPassageiros.map(passageiro => {

                let passagem = passagens.find(p => p.dados.id === passageiro.dados.idPasseioPassagem).dados;
                
                return <Box
                    key={passageiro.vaga.id}
                    p={3}
                    bg={noturno ? "whiteAlpha.50" : "whiteAlpha.600"}
                    // borderWidth={2}
                    rounded={5}
                    _notLast={{
                        mb:2
                    }}
                >
                    <Box>{passageiro.dados.nome}</Box>
                    <Flex justify={"space-between"} mt={2}>
                        <Text fontSize={"sm"}>
                            {passagem.descricao}
                        </Text>
                        <Text>
                            {converterReal(passagem.valor)}
                        </Text>
                    </Flex>
                </Box>
            })}
            <Divider my={3} />
            <Flex align={"center"} justify={"space-between"}>
                <Text>Total</Text>
                <Text fontWeight={"bold"} fontSize={"lg"}>R$ {converterReal(pagamento.totalValor)}</Text>
            </Flex>
            <Box></Box>
        </Container>
    </>

}