import React, { createRef } from "react";
import MaskedInput from 'react-maskedinput';
import { Input } from '@chakra-ui/react';

export default function InputCpf({
    onChange,
    ...props
}){

    // referencias inputs
    let cpfInput = createRef();

    return <>
        <Input 
            ref={cpfInput}
            as={MaskedInput}
            mask="111.111.111-11"
            onChange={(e) => {
                onChange({
                    target: {
                        value: cpfInput.current.mask.getRawValue().split('_').join(''),
                        name: props.name ?? undefined
                    }
                })
            }}
            {...props}
        />
    </>


}