import React, {
    useState,
    createContext,
    useContext,
    useEffect
} from 'react';

const TremCarroContext = createContext({});


// contexto de login
export function TremCarroProvider({ 
    children,
    dadosCarro, 
    dadosVagas=[],
    alterouCarro,
    alterouVaga,
    clicouVaga=null,      // ao clicar sobre uma vaga
    inserir=false,        // habilita componente para inserir
    inseriuVaga=null,
    focarVaga=null,       // id da vaga a se focar
    vagasSelecionadas=[], // as vagas que ficaram na cor verdinha
    vagasConfirmadas=[],  // vagas que estão com status aprovado ou disponível
    vagasEsconder=[],     // vagas para esconder
    vendasVagaSite=[],    // vagas com vendas feitas através do site
    modo="admin"
}) {

    const dimensao = {
        bloco: {
            w: 30,
            h: 30
        }
    };
    
    // // armazena variaveis
    // const [ carro, alterarCarro ] = useState(dadosCarro);
    // const [ vagas, alterarVagas ] = useState(dadosVagas);

    return <TremCarroContext.Provider value={{ 
        dadosCarro, dadosVagas, alterouCarro, alterouVaga, clicouVaga, dimensao,
        modo, inserir, inseriuVaga, focarVaga, vagasSelecionadas, vagasConfirmadas,
        vagasEsconder, vendasVagaSite
    }}>
        {children}
    </TremCarroContext.Provider>
    

}

// finaliza
export function useTremCarro() {
    const context = useContext(TremCarroContext);
    return context;
}