import React, { useState, useEffect } from "react";
import axios from "axios";
import { useMediaQuery, Center, Spinner, Box, Badge, Button, Alert, Image, Flex, Text, useColorModeValue, HStack, Stack, Divider, Heading } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { MdChair, MdOutlineTag } from "react-icons/md";
import PasseioPartidaRetorno from "./../../../Eventos/Apresentar/PasseioPartidaRetorno";
import { usePainelUsuario } from "../../PainelUsuarioContext";
import { capitalizar, converterReal } from "../../../../Helpers";
import moment from "moment";
import { FaBabyCarriage, FaQrcode, FaTrain } from "react-icons/fa";
import { HiOutlineTicket } from "react-icons/hi";

// apresenta os dados do pedido
export default function MeuPedido(){

    // estados, dados
    const { noturno, maiorQue1024, maiorQue768 } = usePainelUsuario();
    const { id } = useParams();
    const [ carregando, alterarCarregando ] = useState(true);
    const [ dados, alterarDados ] = useState(null);
    const [ evento, alterarEvento ] = useState(null);
    const [ card, alterarCard ] = useState(null);
    const [ passageiros, alterarPassageiros ] = useState([]);
    const [ transacao, alterarTransacao ] = useState(null);
    const [ imprimindo, alterarImprimindo ] = useState(false);

    // inicia
    useEffect(() => {
        carregarPedido(); 
    }, []);

    // se carregando
    if(carregando){
        return <>
            <Center>
                <Spinner />
            </Center>
        </>
    }

    return <>

        {/* número do pedido */}
        <Flex fontSize={"xs"} fontWeight={"bold"} align={"center"}>
            Pedido <MdOutlineTag />{dados.id}
        </Flex>

        {/* cabeçalho, informações do evento */}
        <Box 
            p={1} 
            bg={noturno ? "whiteAlpha.100" : "whiteAlpha.800"}
            rounded={3}
            mb={1}
        >
            <Flex gridGap={1} direction={maiorQue1024 ? "row" : "column"}>
                {maiorQue1024 && 
                    <Image 
                        h="auto" 
                        verticalAlign={"top"}
                        w="150px" 
                        src={`${process.env.REACT_APP_URL_ARQUIVOS}/anexos/${card.temporario}`}
                        rounded={3}
                    />
                }
                <Box flex={1}>
                    <Text textAlign={"center"} fontWeight={"bold"} fontSize={"lg"}>{evento.titulo}</Text>
                    <Stack direction={maiorQue1024 ? "row" : "column"}>
                        <PasseioPartidaRetorno 
                            dados={evento}
                        />
                    </Stack>
                </Box>
            </Flex>
        </Box>

        {/* dados da transação */}
        <Box
            p={1} 
            bg={noturno ? "whiteAlpha.100" : "whiteAlpha.800"}
            rounded={3}
            mb={1}
        >
            <Box>
                <Flex
                    align={"center"}
                    justify={"space-between"}
                >
                    <Box>
                        Compra com <b>{descricaoMetodo(transacao.metodo)}</b> no dia <b>{moment(transacao.dataCadastro).format("DD/MM/YYYY [as] HH:mm[h]")}</b>
                    </Box>
                    <Box whiteSpace={"nowrap"}>
                        R$ {converterReal(transacao.total)}
                    </Box>
                </Flex>
                <Flex 
                    align={"center"}
                    justify={"space-between"}
                >
                    <Box>
                        
                    </Box>
                    <Box>
                        <Badge size={"xs"} mb={1} colorScheme={statusTransacao(transacao.status).cor}>
                            {statusTransacao(transacao.status).nome}
                            {/* {transacao.dataStatus && <> - {moment(transacao.dataStatus).format("DD/MM/YYYY")}</>} */}
                        </Badge>
                    </Box>
                </Flex>
            </Box>
        </Box>

        {/* passageiros, somente se venda com status */}
        { ["aguardando", "analise", "aprovado", "disponivel"].includes(transacao.status) &&
            <>
                <Text fontSize="xs" fontWeight={"bold"}>Passageiros</Text>
                {passageiros.map(passageiro => 
                    <Flex
                        key={passageiro.dados.id}
                        mb={1}
                        bg={noturno ? "whiteAlpha.200" : "whiteAlpha.800"}
                        p={1}
                        gridGap={1}
                        direction={maiorQue1024 ? "row" : "column"}
                    >
                        <Box
                            maxW={maiorQue1024 ? "none" : "100px"}
                        >
                            <Flex 
                                align="center" 
                                justify="center" 
                                rounded={5}
                            >
                                <Flex 
                                    fontSize="sm" 
                                    align="center" 
                                    bg={"yellow.200"} 
                                    px={2} 
                                    py={1} 
                                    roundedLeft={5}
                                    flex={1}
                                >
                                    <FaTrain color="black" /> <Text ml={2} fontSize={"xs"} fontWeight="bold" color={"gray.700"}>{passageiro.carro.codigo}</Text>
                                </Flex>
                                <Flex 
                                    fontSize="sm" 
                                    align="center" 
                                    bg={"green.200"} 
                                    px={2} 
                                    py={1} 
                                    roundedRight={5}
                                    flex={1}
                                >
                                    <MdChair color="green" /> <Text ml={2} fontSize={"xs"} fontWeight="bold" color={"gray.700"}>{passageiro.vaga.codigo}</Text>
                                </Flex>
                            </Flex>
                        </Box>
                        <Box
                            flex={1}
                            fontSize={"xs"}
                            // p={1}
                        >
                            <Flex direction={maiorQue1024 ? "row" : "column"}>
                                <Box flex={1}>{capitalizar(passageiro.dados.nome)}</Box>
                                <Box><b>Nascimento</b>: {moment(passageiro.dados.nascimento).format("DD/MM/YYYY")}</Box>
                            </Flex>
                            <Flex gridGap={2} direction={maiorQue1024 ? "row" : "column"}>
                                <Box><b>CPF</b>: {passageiro.dados.cpf}</Box>
                                <Box><b>RG</b>: {passageiro.dados.rg}</Box>
                                {passageiro.dados.telefone && 
                                    <Box><b>Telefone</b>: {passageiro.dados.telefone}</Box>
                                }
                            </Flex>
                            {passageiro.dados.possuiCrianca === "S" && <>
                                <Divider my={1} />
                                <Flex direction={maiorQue1024 ? "row" : "column"}>
                                    <Box flex={1}> <FaBabyCarriage style={{display: "inline"}} /> {capitalizar(passageiro.crianca.nome)}</Box>
                                    <Box><b>Nascimento</b>: {moment(passageiro.crianca.nascimento).format("DD/MM/YYYY")}</Box>
                                </Flex>
                                <Flex gridGap={2} direction={maiorQue1024 ? "row" : "column"}>
                                    <Box><b>CPF</b>: {passageiro.crianca.cpf}</Box>
                                    <Box><b>RG</b>: {passageiro.crianca.rg}</Box>
                                    {passageiro.crianca.certidao && 
                                        <Box><b>Certidão</b>: {passageiro.crianca.certidao}</Box>
                                    }
                                </Flex>
                            </>}

                            {/* PASSAGEM */}
                            <Flex 
                                bg={noturno ?  "green.700" : "green.200"}
                                align="center" 
                                fontSize="sm"
                                p={0.5}
                                rounded={2}
                                flex={1}
                                mt={1}
                            >
                                <Flex align="center">
                                    <Text minW={"70px"} textAlign={"right"} fontWeight="bold"><small>R$</small> {converterReal(passageiro.passagem.valor)}</Text>
                                    <Box mt="1px" mx={2}>
                                        <HiOutlineTicket
                                            color="blue"
                                        />
                                    </Box>
                                    <Text flex={1}>{passageiro.passagem.descricao}</Text>
                                </Flex>
                            </Flex>

                        </Box>
                    </Flex>    
                )}

                {/* se aprovado, apresenta opção para baixar bilhetes */}
                {["aprovado", "disponivel"].includes(transacao.status) && <>
                    <Box
                        textAlign={"right"}
                    >
                        <Button 
                            colorScheme={"green"} 
                            leftIcon={<FaQrcode />}
                            isLoading={imprimindo}
                            onClick={imprimirPassagens}
                        >
                            Imprimir passagens
                        </Button>
                    </Box>
                </>}
            </>
        }
    </>

    // carrega os dados do pedido
    async function carregarPedido(){

        try{

            let { data } = await axios.get(`/meuPedido/${id}`);
            alterarDados(data.dados);
            alterarEvento(data.evento);
            alterarCard(data.card);
            alterarPassageiros(data.passageiros);
            alterarTransacao(data.transacao);
            alterarCarregando(false);

        }catch({response}){}

    }

    // define descrição do método
    function descricaoMetodo(metodo){
        switch(metodo){
            case "cartaoCredito": return "cartão de crédito"; // break; 
            case "cartaoDebito": return "cartão de débito"; //break; 
            case "boleto": return "boleto"; //break; 
            case "dinheiro": return "dinheiro"; //break; 
            case "pix": return "pix"; //break; 
            case "outros": return "outros"; //break; 
            default:
        }
    }

    // define o status da transação
    function statusTransacao(status){

        // identifica o status da transação
        let statusInfo = { nome: "Aguardando Confirmação", cor: "gray" };
        switch(status){
            case "aprovado": case "disponivel":
                statusInfo.nome = "Compra Aprovada";
                statusInfo.cor = "green"; 
            break;
            case "disputa": 
                statusInfo.nome = "Em Disputa";
                statusInfo.cor = "red"; 
            break;
            case "devolvida": case "cancelada":
                statusInfo.nome = "Compra Cancelada";
                statusInfo.cor = "red"; 
            break;
            default: 
        }

        // retorna
        return statusInfo;
    }

    // imprimir passagens
    async function imprimirPassagens(){
        alterarImprimindo(true);

        try{

            // imprime os bilhetes
            let { data } = await axios.get(`/transacao/${transacao.id}/bilhetes`);
            window.open(`${process.env.REACT_APP_URL_ARQUIVOS}/anexos/${data.arquivo}`,'_blank');

        }catch({response}){}finally{
            alterarImprimindo(false);
        }
    }


}