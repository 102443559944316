import React, { useEffect, useState } from "react";
import { TremCarroProvider, useTremCarro } from "./TremCarroContext";
import { Spinner, HStack, Text, Input, useNumberInput, Box, Button, Flex, Image, useColorModeValue } from '@chakra-ui/react';
import { FiRotateCw } from "react-icons/fi";
import { FaArrowRight, FaArrowDown, FaArrowLeft, FaArrowUp } from "react-icons/fa";
import * as R from "ramda";

import Carro from "./Carro";
import Vagas from "./Vagas";

// retorna trem
export default function TremCarro({...props}){

    return <TremCarroProvider {...props}>
        <TremCarroContainer />
    </TremCarroProvider>
}

// transforma na função principal
function TremCarroContainer(){



    let { dadosCarro, dimensao, modo } = useTremCarro();

    let h = (dadosCarro.desenho.h * dimensao.bloco.h) + 12;
    let w = (dadosCarro.desenho.w * dimensao.bloco.w) + 12;

    return <>

        <Box
            rounded={10}
        >
            <Box
                as="svg"
                borderWidth={4}
                borderColor="museu.600"
                rounded={30}
                bg="#fdcd7b"
                pl={`4px`}
                pt={`4px`}
                viewBox={`0 0 ${(dadosCarro.desenho.w * dimensao.bloco.w)} ${(dadosCarro.desenho.h * dimensao.bloco.h)}`}
                // w="100%"
                h={h}
                w={w}

            >
                {modo === "admin" && <Carro />}
                
                <Vagas />

            </Box>
        </Box>
    </>

}

