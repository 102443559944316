import React, { useState, createRef } from "react";
import axios from "axios";
import {
    Flex,
    Input,
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Select,
    Divider
} from '@chakra-ui/react';
import { useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import InputData from "./../../../../Componentes/InputData";
import MaskedInput from 'react-maskedinput';

export default function FormularioCadastro({
    dados,
    alterarDados,
    erros=[],
    cpf=true,
    mostrarLogin=false,
    size="md"
}){

    const [ maiorQue768 ] = useMediaQuery('(min-width: 768px)');
    const noturno = useColorModeValue(false, true);

    // referencias inputs
    let cpfInput = createRef();
    let cepInput = createRef();
    let telResInput = createRef();
    let telCelInput = createRef();

    const camposFlexStyle = {
        direction: maiorQue768 ? "row" : "column",
        mb: 4
    };
    const formControlStyle = {
        _notLast: {
            mr: 4,
            mb: maiorQue768 ? 0 : 4,
        },
        [!maiorQue768 && `maxW`]: null
    };
    const formLabelStyle = {
        fontSize: maiorQue768 ? size : "sm"
    };
    const inputStyle = {
        bg: noturno ? "none" : "white",
        size: maiorQue768 ? size : "sm",
        onChange: (e) => {
            if(e.target.name){
                alterarDados({...dados, [e.target.name]: e.target.value});
            }
        }
    };

    // busca cep
    async function buscarCep(){


        let buscarCepConexao = axios.create({ headers: null });

        // se foi totalmente preenchido
        if(dados.cep.length === 8){

            try{
                let { data } = await buscarCepConexao.get(`https://viacep.com.br/ws/${dados.cep}/json`, { responseType: 'json' });

                alterarDados({
                    ...dados, 
                    cidade: data.localidade,
                    estado: data.uf,
                    endereco: data.logradouro,
                    bairro: data.bairro
                });
            }catch({response}){}
        }
    }

    return <>
        <Flex {...camposFlexStyle}>
            <FormControl isInvalid={erros.nome} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Nome*</FormLabel>
                <Input name="nome" value={dados.nome} {...inputStyle} />
                {(erros.nome) && <FormHelperText color="red">{erros.nome}</FormHelperText> }
            </FormControl>
            <FormControl isInvalid={erros.nascimento} maxW={200} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Nascimento*</FormLabel>
                <InputData name="nascimento" value={dados.nascimento} {...inputStyle} />
                {(erros.nascimento) && <FormHelperText color="red">{erros.nascimento}</FormHelperText> }
            </FormControl>
        </Flex>

        <Flex {...camposFlexStyle}>
            <FormControl isInvalid={erros.rg} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>RG</FormLabel>
                <Input name="rg" value={dados.rg ?? ""} {...inputStyle} />
                {(erros.rg) && <FormHelperText color="red">{erros.rg}</FormHelperText> }
            </FormControl>
            <FormControl isInvalid={erros.cpf} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>CPF*</FormLabel>
                <Input 
                    disabled={!cpf} 
                    name="cpf" 
                    value={dados.cpf} 
                    {...inputStyle}
                    ref={cpfInput}
                    as={MaskedInput}
                    mask="111.111.111-11"
                    onChange={(e) => {
                        alterarDados({...dados, cpf: cpfInput.current.mask.getRawValue().split('_').join('')});
                    }}
                />
                {(erros.cpf) && <FormHelperText color="red">{erros.cpf}</FormHelperText> }
            </FormControl>
        </Flex>

        <Flex {...camposFlexStyle}>
            <FormControl isInvalid={erros.telefoneResidencial} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Telefone Residencial</FormLabel>
                <Input 
                    name="telefoneResidencial" 
                    value={dados.telefoneResidencial} 
                    {...inputStyle} 
                    ref={telResInput}
                    as={MaskedInput}
                    mask="(11) 1111-11111"
                    onChange={(e) => {
                        alterarDados({...dados, telefoneResidencial: telResInput.current.mask.getRawValue().split('_').join('')});
                    }}
                />
                {(erros.telefoneResidencial) && <FormHelperText color="red">{erros.telefoneResidencial}</FormHelperText> }
            </FormControl>
            <FormControl isInvalid={erros.telefoneCelular} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Telefone Celular*</FormLabel>
                <Input 
                    name="telefoneCelular" 
                    value={dados.telefoneCelular} 
                    {...inputStyle} 
                    ref={telCelInput}
                    as={MaskedInput}
                    mask="(11) 11111-1111"
                    onChange={(e) => {
                        alterarDados({...dados, telefoneCelular: telCelInput.current.mask.getRawValue().split('_').join('')});
                    }}
                />
                {(erros.telefoneCelular) && <FormHelperText color="red">{erros.telefoneCelular}</FormHelperText> }
            </FormControl>
        </Flex>

        <Flex {...camposFlexStyle}>
            <FormControl isInvalid={erros.cep} maxW={150} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>CEP*</FormLabel>
                <Input 
                    name="cep" 
                    value={dados.cep} 
                    {...inputStyle} 
                    as={MaskedInput}
                    mask="11111-111"
                    ref={cepInput}
                    onChange={(e) => {
                        let cepNovo = cepInput.current.mask.getRawValue().split('_').join('');
                        alterarDados({...dados, cep: cepNovo});
                    }}
                    onBlur={() => {
                        buscarCep();
                    }}
                />
                {(erros.cep) && <FormHelperText color="red">{erros.cep}</FormHelperText> }
            </FormControl>
            <FormControl isInvalid={erros.endereco} {...formControlStyle} flex={1}>
                <FormLabel {...formLabelStyle}>Endereço*</FormLabel>
                <Input name="endereco" value={dados.endereco} {...inputStyle} />
                {(erros.endereco) && <FormHelperText color="red">{erros.endereco}</FormHelperText> }
            </FormControl>
            <FormControl isInvalid={erros.numero} maxW={100} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Número</FormLabel>
                <Input name="numero" value={dados.numero} {...inputStyle} />
            </FormControl>
        </Flex>

        <Flex {...camposFlexStyle}>
            <FormControl isInvalid={erros.bairro} flex={1} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Bairro*</FormLabel>
                <Input name="bairro" value={dados.bairro} {...inputStyle} />
                {(erros.bairro) && <FormHelperText color="red">{erros.bairro}</FormHelperText> }
            </FormControl>
            <FormControl isInvalid={erros.complemento} maxW={200} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Complemento</FormLabel>
                <Input name="complemento" value={dados.complemento ?? ""} {...inputStyle} />
                {(erros.complemento) && <FormHelperText color="red">{erros.complemento}</FormHelperText> }
            </FormControl>
        </Flex>

        <Flex {...camposFlexStyle}>
            <FormControl isInvalid={erros.cidade} flex={1} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Cidade*</FormLabel>
                <Input name="cidade" value={dados.cidade} {...inputStyle} />
                {(erros.cidade) && <FormHelperText color="red">{erros.cidade}</FormHelperText> }
            </FormControl>
            <FormControl isInvalid={erros.estado} maxW={100} {...formControlStyle}>
                <FormLabel {...formLabelStyle}>Estado*</FormLabel>
                <Select name="estado" value={dados.estado} {...inputStyle}>
                    {[
                        'AC', 'AL', 'AP', 'AM', 'BA', 'CE', 
                        'DF', 'ES', 'GO', 'MA', 'MT', 'MS', 
                        'MG', 'PA', 'PB', 'PR', 'PE', 'PI',
                        'RJ', 'RN', 'RS', 'RO', 'RR', 'SC',
                        'SP', 'SE', 'TO'
                    ].map(uf => <option key={uf} value={uf}>{uf}</option>)}
                </Select>
                {(erros.estado) && <FormHelperText color="red">{erros.estado}</FormHelperText> }
            </FormControl>
        </Flex>

        {mostrarLogin && 
            <>
                <Divider my={4} />


                <Flex {...camposFlexStyle}>
                    <FormControl isInvalid={erros.email} flex={1} {...formControlStyle}>
                        <FormLabel {...formLabelStyle}>Email*</FormLabel>
                        <Input type="email" name="email" value={dados.email} {...inputStyle} />
                        {(erros.email) && <FormHelperText color="red">{erros.email}</FormHelperText> }
                    </FormControl>
                </Flex>
                <Flex {...camposFlexStyle}>
                    <FormControl isInvalid={erros.senha} flex={1} {...formControlStyle}>
                        <FormLabel {...formLabelStyle}>Senha*</FormLabel>
                        <Input type="password" name="senha" value={dados.senha} {...inputStyle} />
                        {(erros.senha) && <FormHelperText color="red">{erros.senha}</FormHelperText> }
                    </FormControl>
                    <FormControl flex={1} {...formControlStyle}>
                        <FormLabel {...formLabelStyle}>Confirmar Senha*</FormLabel>
                        <Input 
                            isInvalid={dados.confirmarSenha !== null && dados.confirmarSenha !== dados.senha}
                            type="password" 
                            name="confirmarSenha" 
                            {...inputStyle}  
                        />
                    </FormControl>
                </Flex>

                <Divider my={4} />

            </>
        }


    </>
}