import React, { createRef } from "react";
import MaskedInput from 'react-maskedinput';
import { Input } from '@chakra-ui/react';

export default function InputTel({
    onChange,
    ...props
}){

    // referencias inputs
    let telInput = createRef();

    return <>
        <Input 
            ref={telInput}
            as={MaskedInput}
            mask="(11) 1111-11111"
            onChange={(e) => {
                onChange({
                    target: {
                        value: telInput.current.mask.getRawValue().split('_').join(''),
                        name: props.name ?? undefined
                    }
                })
            }}
            {...props}
        />
    </>


}