import React from "react";
import { Alert, Box, Button, Center, AlertIcon } from "@chakra-ui/react";
import { useComprarPassagem } from "../ComprarPassagemContext";
import TremCarro from "./../../../Componentes/TremCarro"; 
import LegendaVagas from "./../../../Componentes/TremCarro/LegendaVagas";

export default function DesenhoCarro(){

    const { maiorQue1024, focarVaga, carros, carroSelecionado, alterarEtapa, incluirPassageiro, listaPassageiros, alterarErrosPassageiros } = useComprarPassagem();


    if(carros[carroSelecionado].dados.liberarVenda === "N"){
        return <Center>
            <Alert status='info' rounded={3}>
                <AlertIcon />
                Este vagão não está disponível para compras!
            </Alert>
        </Center>
    }

    return <>
        {/* poltronas */}
        <Box
            maxW={maiorQue1024 ? "default" : "100%"}
        >
            
            <Box
                overflow={maiorQue1024 ? "initial" : "auto"}
            >
                <LegendaVagas />

                <TremCarro 
                    key={carros[carroSelecionado].dados.id}
                    dadosCarro={carros[carroSelecionado].dados}
                    dadosVagas={carros[carroSelecionado].vagas.map(vaga => vaga.dados)}
                    modo={"cliente"}
                    clicouVaga={vaga => {
                        if(vaga.ocupada === "N"){

                            // incrementa vagas a serem ocupadas
                            incluirPassageiro(vaga);
                            alterarErrosPassageiros([]);

                        }
                    }}
                    focarVaga={focarVaga}
                    vagasSelecionadas={listaPassageiros.map(lp => lp.vaga.id)}
                />
            </Box>

            {!maiorQue1024 && 
                <Center my={5}>
                    <Button size="lg" colorScheme="blue" onClick={() => alterarEtapa("passageiros")} >Tudo pronto!</Button>
                </Center>
            }

        </Box>
    </>

}