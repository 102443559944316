import React, { useEffect, useState } from "react";
import axios from "axios";
import { Flex, Spinner, Container, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import Script from 'react-load-script';

import { ComprarPassagemProvider, useComprarPassagem } from "./ComprarPassagemContext";
import Etapas from "./Etapas";
import DesenhoTrem from "./DesenhoTrem";
import DesenhoCarro from "./DesenhoCarro";
import Passageiros from "./Passageiros";
import Pagamento from "./Pagamento";
import Finalizada from "./Finalizada";
import InformacaoPasseio from "./InformacaoPasseio";

export default function ComprarPassagem(){

    return <ComprarPassagemProvider>
        <ComprarPassagemContainer />
    </ComprarPassagemProvider>

}

// inicializa
function ComprarPassagemContainer(){

    // estados
    const { id } = useParams();
    const toast = useToast();
    const { maiorQue1024, alterarDados, alterarCarros, alterarPassagens, ambientePagseguro, alterarAmbientePagseguro, alterarCard, etapa } = useComprarPassagem();
    const [ carregando, alterarCarregando ] = useState(true);
    const [ libPagseguroCarregada, alterarLibPagseguroCarregada ] = useState(true);

    // inicializa
    useEffect(() => {
        consultarPasseioVagas();
    }, []);

    // carrega todas as informações necessárias
    async function consultarPasseioVagas(){

        try{

            let { data } = await axios.get(`/eventos/${id}/passeioVagas`);
            alterarAmbientePagseguro(data.ambientePagseguro);
            alterarDados(data.dados);
            alterarCarros(data.passeio.carros);
            alterarPassagens(data.passeio.passagens);
            alterarCard(data.card);
            alterarCarregando(false);

            // mensagem de bem sucedido
            toast({
                description: "Comece escolhendo as poltronas que deseja para você e sua família!",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

        }catch({response}){

            if(response){
                let mensagem = null;
                if(response.data.indisponivel){
                    mensagem = response.data.indisponivel;
                }
                if(response.data.semPassagens){
                    mensagem = response.data.semPassagens;
                }
    
                // mensagem de bem sucedido
                toast({
                    title: 'Nao foi possível acessar o passeio!',
                    description: mensagem,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        }

    }

    // autentica no pagseguro
    async function autenticarPagseguro(){

        try{
            
            let { data } = await axios.get(`/transacao/sessaoPagseguro`);
            window.PagSeguroDirectPayment.setSessionId(data.sessao);

        }catch({response}){
            alterarLibPagseguroCarregada(false);
        }

    }

    // se estiver carregando
    if(carregando){
        return <>
            <Flex
                p={10}
                align="center"
                justify="center"
            >
                <Spinner />
            </Flex>
        </>
    }

    if(!libPagseguroCarregada){
        return <>
            <Flex
                p={10}
                align="center"
                justify="center"
            >
                Oops, algo deu errado, tente mais tarde!
            </Flex>
        </>
    }

    // se a venda foi finalizada apresenta tela
    if(etapa  === "finalizada"){
        return <Finalizada />
    }
    
    return <>
        <Script 
            url={`https://stc${ambientePagseguro === "sandbox" ? ".sandbox" : ""}.pagseguro.uol.com.br/pagseguro/api/v2/checkout/pagseguro.directpayment.js`}
            onCreate={() => {}}
            onError={() => {
                alterarLibPagseguroCarregada(false);
            }}
            onLoad={() => {
                autenticarPagseguro();
            }}
        />
        <Container
            maxW='container.lg'
            my={30}
        >

            <InformacaoPasseio />

            <Etapas />

            { etapa === "vagas" && 
                <>
                    <DesenhoTrem />
                </>
            }


            <Flex
                flex={1}
                direction={maiorQue1024 ? "row" : "column"}
                justify="center"
                alignItems="center"
            >

                { etapa === "vagas" && 
                    <DesenhoCarro />
                }

                {(etapa === "passageiros" || (etapa === "vagas" && maiorQue1024)) &&
                    <Passageiros />
                }


            </Flex>

            { etapa === "pagamento" && 
                <Pagamento />
            }

        </Container>
    </>
}   