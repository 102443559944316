import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Flex, FormControl, Container, Image, Spinner, Button, Input, FormHelperText, Text, Link, useToast, useBoolean, useMediaQuery, Grid, GridItem, useColorModeValue, Center } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import useIsMounted from 'ismounted';
import moment from "moment";
import miniBanner from "./miniBanner.jpg";
import { FaWhatsapp } from 'react-icons/fa';

export default function Eventos({
    venda=false,
    tipo="passeio",
    limitado=false,
    faixa=true
}){

    const navigate = useNavigate();
    const noturno = useColorModeValue(false, true);
    const [ maiorQue768 ] = useMediaQuery('(min-width: 768px)');
    const [ maiorQue1280 ] = useMediaQuery('(min-width: 1280px)');
    const [ carregando, alterarCarregando ] = useState(true);
    const [ registros, alterarRegistros ] = useState([]);
    const [ parametros, alterarParametros] = useState({
        tipo,
        status: venda ? [3] : [2, 3, 4],
        pagina: limitado ? 1 : null,
        registrosPorPagina: limitado ? 8 : null
    });

    // cards por tela
    let limiteCards = 1;
    if(maiorQue768) limiteCards = 3;
    if(maiorQue1280) limiteCards = 4;

    // inicializa
    useEffect(() => {
        consultarRegistros();
    }, [parametros]);

    // consulta os eventos
    async function consultarRegistros(){

        try{

            // consulta
            let { data } = await axios.get(`/eventos/site`, { params: parametros });
            alterarRegistros(data.registros);

        }catch({response}){}
        finally{
            alterarCarregando(false);
        }
    }

    // se não encontrado nenhum registro, apresenta uma mensagem de conforto
    if(!carregando && registros.length === 0){
        return <Container 
            my={15}
            minH={"40vh"}
            maxW='container.xl'
        >
            <Center mt={10} w="full">
                <Text display="inline" fontSize={"2xl"} fontWeight={"bold"}>
                    
                    <Flex 
                        as={Link} 
                        align="center" 
                        // color={corTexto}
                        href="https://api.whatsapp.com/send/?phone=5548988681414"
                        target="_blank"
                    >
                        Compre através do nosso whatsapp <FaWhatsapp style={{margin: "0 10px"}} /> (48) 9 8868-1414
                    </Flex>
                </Text>
            </Center>
        </Container>
    }

    return <>
        {faixa && 
            <Box 
                h={"140px"}
                position={"relative"}
            >
                <Image src={miniBanner} display={"inline"} height={"100%"} width={`100%`} objectFit={"cover"} />
                <Center
                    position={"absolute"}
                    backdropFilter={noturno ? "sepia(0.8) grayscale(1) contrast(1.4)" : "sepia(0.8)"}
                    top={0}
                    left={0}
                    bottom={0}
                    right={0}
                >
                    <Text textShadow={"0 0 10px #4a4545"} fontWeight={"bold"} fontSize={"4xl"} color="gray.100">
                        {(!venda && tipo === "passeio") && "Passeios"}
                        {(venda && tipo === "passeio") && "Comprar passagens"}
                        {(tipo === "evento") && "Eventos"}
                        {(tipo === "acervo") && "Acervos"}
                    </Text>
                </Center>
            </Box>
        }

        <Container
            maxW='container.xl'
            // minHeight={"70vh"}
            mt={0}
        >

            {carregando && 
                <Center
                    p={10}
                    align="center"
                    justify="center"
                >
                    <Spinner size={"xl"} color={"museu.600"} />
                </Center>
            }

            <Grid 
                justify="center" 
                align="center"
                // bg="whiteAlpha.100"
                rounded={10}
                // p={5}
                my={10}
                flexWrap="wrap"
                templateColumns={`repeat(${limiteCards}, 1fr)`}
                gap={6}
                alignItems={"baseline"}
            >
                {registros.map((registro, i) => 
                    <GridItem
                        key={i}
                        w="100%"
                        bg={noturno ? "gray.800" : "gray.50"}
                        rounded={5}
                        boxShadow="lg"
                        overflow="hidden"
                        _hover={{
                            boxShadow:"2xl",
                            cursor: "pointer",
                            bg: noturno ? "gray.900" : "gray.100" //"museu.800"
                        }}
                        onClick={() => {

                            let url = "";
                            switch(registro.dados.tipo){
                                case "passeio": url = venda ? "/passeios-comprar" : "/passeios"; break;
                                case "evento": url = "/eventos"; break;
                                case "acervo": url = "/acervo"; break;
                                default:
                            }

                            // navega até a página
                            navigate(`${url}/${registro.dados.id}`);
                        }}
                    >

                        {registro.card && 
                            <Image 
                                // roundedTop={10} 
                                // width="100%" 
                                width={`${100 / limiteCards}vw`}
                                src={`${process.env.REACT_APP_URL_ARQUIVOS}/anexos/${registro.card.temporario}`}
                            />
                        }
                        <Box p={2} pb={0} color={noturno ? "gray.100" : "museu.600"} fontWeight="bold" fontSize={"lg"} >
                            {(registro.dados.tipo === "passeio" && registro.dados.saidaPartida) && <>{ moment(registro.dados.saidaPartida).format("DD/MM/YYYY") }</>}
                            {(registro.dados.tipo === "evento" && registro.dados.dataEvento) && <>{ moment(registro.dados.dataEvento).format("DD/MM/YYYY") }</>}
                        </Box>
                        <Box
                            p={3} pt={0}
                        >
                            <Text color={noturno ? "gray.100" : "museu.600"} noOfLines={3} >{registro.dados.titulo}</Text>
                        </Box>
                    </GridItem>
                )}
            </Grid>
        </Container>
    </>



}