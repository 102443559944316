
// importa as imagens
import poltronaT1_1_0 from './poltrona_t1/poltrona_t1_1_0.png';
import poltronaT2_1_0 from './poltrona_t2/poltrona_t2_1_0.png';
import poltronaT3_1_0 from './poltrona_t3/poltrona_t3_1_0.png';
import poltronaT4_1_0 from './poltrona_t4/poltrona_t4_1_0.png';
import poltronaT1_2_0 from './poltrona_t1/poltrona_t1_2_0.png';
import poltronaT2_2_0 from './poltrona_t2/poltrona_t2_2_0.png';
import poltronaT3_2_0 from './poltrona_t3/poltrona_t3_2_0.png';
import poltronaT4_2_0 from './poltrona_t4/poltrona_t4_2_0.png';
import poltronaT1_3_0 from './poltrona_t1/poltrona_t1_3_0.png';
import poltronaT2_3_0 from './poltrona_t2/poltrona_t2_3_0.png';
import poltronaT3_3_0 from './poltrona_t3/poltrona_t3_3_0.png';
import poltronaT4_3_0 from './poltrona_t4/poltrona_t4_3_0.png';
import poltronaT1_4_0 from './poltrona_t1/poltrona_t1_4_0.png';
import poltronaT2_4_0 from './poltrona_t2/poltrona_t2_4_0.png';
import poltronaT3_4_0 from './poltrona_t3/poltrona_t3_4_0.png';
import poltronaT4_4_0 from './poltrona_t4/poltrona_t4_4_0.png';
import poltronaT1_5_0 from './poltrona_t1/poltrona_t1_5_0.png';
import poltronaT2_5_0 from './poltrona_t2/poltrona_t2_5_0.png';
import poltronaT3_5_0 from './poltrona_t3/poltrona_t3_5_0.png';
import poltronaT4_5_0 from './poltrona_t4/poltrona_t4_5_0.png';


const icones = {
    1: {
        livre: poltronaT1_1_0,
        focada: poltronaT1_2_0,
        ocupada: poltronaT1_3_0,
        temp1: poltronaT1_4_0,
        reserva: poltronaT1_5_0,
    },
    2: {
        livre: poltronaT2_1_0,
        focada: poltronaT2_2_0,
        ocupada: poltronaT2_3_0,
        temp1: poltronaT2_4_0,
        reserva: poltronaT2_5_0,
    },
    3: {
        livre: poltronaT3_1_0,
        focada: poltronaT3_2_0,
        ocupada: poltronaT3_3_0,
        temp1: poltronaT3_4_0,
        reserva: poltronaT3_5_0,
    },
    4: {
        livre: poltronaT4_1_0,
        focada: poltronaT4_2_0,
        ocupada: poltronaT4_3_0,
        temp1: poltronaT4_4_0,
        reserva: poltronaT4_5_0,
    }
};

icones[5] = Object.assign({}, icones[1]);

export { icones };