import React from "react";
import { Spinner, Box, Button, Flex, Image, Input, Popover, PopoverTrigger, Center, Portal, PopoverContent, PopoverArrow, PopoverHeader, PopoverCloseButton, PopoverBody, PopoverFooter, Badge } from '@chakra-ui/react';
import { FaPlus, FaTrash } from "react-icons/fa"

// carrega a imagem
import imgLocomotiva from "./locomotiva.png";
import imgVagao from "./vagao.png";

// cria trilho
export default function TremMenu({
    lista=[],
    carroFocado=null,
    clicouCarro=null,
    bg="white",
    wh="98px",
    mostrarExcluir=false,
    clicouExcluir,
    mostrarCadastrar=false,
    clicouCadastrar,
    carregando=false,
    carrosLotados=[]
}){

    // let tempCarros = [
    //     {id: 1, codigo: "02", desenho: {w: 10, h: 10}, posicao: 0},
    //     {id: 2, codigo: "03", desenho: {w: 10, h: 10}, posicao: 0},
    //     {id: "255", codigo: "04", desenho: {w: 10, h: 10}, posicao: 0},
    //     {id: 4, codigo: "05", desenho: {w: 10, h: 10}, posicao: 0},
    //     {id: 5, codigo: "06", desenho: {w: 10, h: 10}, posicao: 0},
    // ];


    return <>
        <Flex
            w="100%"
            wrap={"wrap"}
            justify="start"
        >
            <Box>
                <Image src={imgLocomotiva} w={wh} h={wh} verticalAlign="middle" />
            </Box>
            {lista.map((trem, posicao) => 
                <Box
                    key={trem.id ?? trem._tempId}
                    ml={2}
                    position={"relative"}
                    cursor={"pointer"}
                    _hover={{
                        bg: "orange.100", // bg: "orange.200" = selecionado

                    }}
                    bg={(carroFocado === trem.id || carroFocado === trem._tempId) ? "orange.200" : bg}
                    rounded={10}
                    p={1}
                    onClick={() => {
                        if(carregando) return;
                        clicouCarro(trem, posicao);
                    }}
                    sx={{
                        "&:hover": {
                            "& .excluir": {
                                display: "inline"
                            }
                        }
                    }}
                    
                >
                    <Image  src={imgVagao} w={wh} h={wh} verticalAlign="middle" opacity={carregando ? 0.5 : 1} />

                    <Flex
                        {...{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            align: "center",
                            justify: "center",
                            zIndex: 2,
                            pointerEvents: "none"
                        }}
                        opacity={carregando ? 0.3 : 1}
                    >
                        <Box 
                            {...{
                                bg: "gray.900",
                                w: "40px",
                                color: "white",
                                p: 0,
                                h: "auto",
                                mt: 2,
                                fontSize: "sm",
                                fontWeight: "bold",
                                textAlign: "center",
                                rounded: 10,
                                disabled: true,
                                borderWidth: 1
                            }}
                        >
                            {trem.codigo}
                        </Box>
                        {carrosLotados.includes(trem.id) && <>
                            <Box
                                position={"absolute"}
                                bottom={2}
                                bg={"red.500"}
                                fontSize={"xs"}
                                p={0}
                                h={"auto"}
                                px={2}
                                rounded={5}
                            >
                                Indisponível
                            </Box>
                        </>}
                    </Flex>

                    {mostrarExcluir &&
                        <>
                            <Popover closeOnBlur>
                                <PopoverTrigger>
                                    <Button
                                        display="none"
                                        className="excluir"
                                        position={"absolute"}
                                        top={1}
                                        right={1}
                                        size={"xs"}
                                        colorScheme={"red"}
                                        disabled={carregando}
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                    >
                                        <FaTrash />
                                    </Button>
                                </PopoverTrigger>
                                <PopoverContent >
                                    <PopoverArrow />
                                    <PopoverCloseButton />
                                    <PopoverBody>
                                        <Box>
                                            Tem certeza que deseja exluir?
                                        </Box>
                                        <Box textAlign={"center"} mt={2}>
                                            <Button 
                                                size="xs"
                                                colorScheme='red'
                                                onClick={e => {
                                                    e.stopPropagation();
                                                    clicouExcluir(trem, posicao);
                                                }}
                                                isLoading={carregando}
                                            >
                                                Excluir
                                            </Button>
                                        </Box>
                                    </PopoverBody>
                                </PopoverContent>
                            </Popover>
                        </>
                    }

                    {carregando && 
                        <Center position={"absolute"} top={2} right={0} left={0} m={"auto"}>
                            <Spinner color={"green.500"} size={"lg"} zIndex={10}/>
                        </Center>
                    }
                </Box>
            )}
            { mostrarCadastrar &&
            <Center ml={5}>
                <Button onClick={clicouCadastrar} isLoading={carregando}>
                    <FaPlus />
                </Button>
            </Center>
            }
        </Flex>
    </>
}