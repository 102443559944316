import React, { useState } from "react";
import axios from "axios";
import { Box, Flex, FormControl, Container, Button, Input, FormHelperText, Link, useToast, useBoolean } from "@chakra-ui/react";
import { useLogin } from "./LoginContext";
import { useNavigate } from "react-router-dom";
import useIsMounted from 'ismounted';

// pagina de login do usuário
export default function Login(){

    // estados
    const isMounted = useIsMounted();
    const toast = useToast()
    const [ autenticando, alterarAutenticando ] = useState(false);
    const [ dados, alterarDados ] = useState({
        email: "",
        senha: ""
    });
    const [ erros, alterarErros ] = useState({});
    const login = useLogin();
    const navigate = useNavigate();
    const [ esqueceu, alterarEsqueceu ] = useBoolean(false);
    const [ enviandoEmail, alterarEnviandoEmail ] = useState(false);

    // autenticando
    async function autenticar(){
        alterarErros({});
        alterarAutenticando(true);

        // faz a requisição e autentica
        try{
            let { data } = await axios.post(`/login`, dados);
            
            // altera o login
            login.atualizar(data.dados, data.token);

            toast({
                title: 'Bem vindo!',
                description: "Agora você está logado!",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });

            // após autenticado, atualiza a rota para entrar no caminho correto
            // navigate("");

        }catch({response}){
            if(response && response.status && response.status === 400){
                alterarErros(response.data);
            }
        }finally{
            if (isMounted.current) {
                alterarAutenticando(false);
            }
        }
    }

    async function enviarEmailSenha(){
        alterarEnviandoEmail(true);

        try{
            // faz a requisição pra lembrar
            let { data } = await axios.post(`/recuperarSenha`, dados);

            toast({
                title: 'Pronto, falta pouco!',
                description: "Acesse sua caixa de entrada para recuperar sua senha!",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }catch({response}){
            toast({
                title: 'Oops, algo deu errado!',
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
        }finally{
            alterarEnviandoEmail(false);
        }
    }

    return <>
        <Container
            minHeight={"70vh"}
        >
            <Flex justify="center" align="flex-start">
                <Box
                    bg="whiteAlpha.200"
                    rounded={3}
                    p={10}
                    mt={20}
                    mb={20}
                    w={"100%"}
                >
                    <FormControl>
                        {/* <FormLabel>Email</FormLabel> */}
                        <Input 
                            isInvalid={erros.email ? true : false}
                            variant='flushed'
                            placeholder="Email"
                            onChange={(e) => {
                                alterarDados({...dados, email: e.target.value});
                            }}
                            type='email' 
                            autoComplete="off" 
                            focusBorderColor="museu.600"
                        />
                        {erros.email && <FormHelperText color="red.600">{erros.email}</FormHelperText>}

                        <Input 
                            isInvalid={erros.senha ? true : false}
                            mt={4}
                            placeholder="Senha"
                            onChange={(e) => {
                                alterarDados({...dados, senha: e.target.value});
                            }}
                            variant='flushed'
                            type="password"
                            focusBorderColor="museu.600"
                            hidden={esqueceu}
                        />
                        {erros.senha && <FormHelperText color="red.600">{erros.senha}</FormHelperText>}
                        {erros.novaSenha && <FormHelperText color="green.500">{erros.novaSenha}</FormHelperText>}

                        <Box textAlign={"right"} mt={4}>
                            {esqueceu ? 
                                <>
                                    <Button isLoading={enviandoEmail} onClick={enviarEmailSenha} mr={2}>Lembrar</Button>
                                </>
                                :
                                <>
                                    <Button disabled={autenticando} onClick={() => {navigate("/cadastrar")}} mr={2}>Cadastrar</Button>
                                    <Button isLoading={autenticando} onClick={autenticar} colorScheme={"green"}>Entrar</Button>
                                </>
                            }
                        </Box>
                        
                        <FormHelperText as={Link} onClick={alterarEsqueceu.toggle} hidden={enviandoEmail}>
                            {esqueceu ? "Opa, lembrei!" : "Esqueceu a senha?"}
                        </FormHelperText>
                    </FormControl>
                </Box>
            </Flex>
        </Container>
    </>


}