import React from "react";
import { Box, Flex, Container, Button } from "@chakra-ui/react";
import { Routes, Route, useParams, useNavigate } from "react-router-dom";

import { PainelUsuarioProvider, usePainelUsuario } from "./PainelUsuarioContext";

// componentes
import MeusDados from "./MeusDados";
import Pedidos from "./Pedidos";
import MeuPedido from "./Pedidos/MeuPedido";

export default function PainelUsuario(){

    return <PainelUsuarioProvider>
        <PainelUsuarioContainer />
    </PainelUsuarioProvider>

}

// painel destinado a quem é usuário e está logado
function PainelUsuarioContainer(){

    // dados
    const navigate = useNavigate();
    const { noturno, maiorQue768 } = usePainelUsuario();

    let botaoMenuEstilo = {
        mb: 0,
        // colorScheme: "red",
        borderColor: "museu.600",
        borderWidth: 2,
        rounded: 2,
        color: noturno ? "white" : "museu.600",
        bg: noturno ? "museu.600" : "default",
        justifyContent: "left",
        _hover: {
            bg: noturno ? "museu.600" : "default"
        },
        _active: {
            bg: noturno ? "museu.700" : "default"
        },
        _notLast: {
            mb: 5
        }
    };

    return <>
        <Container
            maxW='container.lg'
            my={30}
        >
            <Flex
                bg={noturno ? "gray.700" : "gray.100"}
                p={5}
                rounded={10}
                direction={maiorQue768 ? "row" : "column"}
            >
                <Flex
                    p={maiorQue768 ? 5 : 0}
                    pb={5}
                    rounded={10}
                    direction={"column"}
                >
                    <Button
                        {...botaoMenuEstilo}
                        onClick={() => { navigate(""); }}
                    >
                        Meus dados
                    </Button>
                    <Button
                        {...botaoMenuEstilo}
                        onClick={() => { navigate("pedidos"); }}
                    >
                        Pedidos
                    </Button>
                </Flex>
                
                <Box
                    flex={1}
                    p={maiorQue768 ? 5 : 0}
                >
                    <Routes>
                        <Route index element={<MeusDados />} />
                        <Route path="/pedidos" element={<Pedidos />} />
                        <Route path="/pedidos/:id" element={<MeuPedido />} />                        
                    </Routes>
                </Box>
            </Flex>
        </Container>

    </>

}