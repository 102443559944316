import React, { Suspense } from "react";

// componentes
import { Box } from "@chakra-ui/react";
import { Routes, Route, useParams, Navigate } from "react-router-dom";
import Fallback from './../Componentes/Fallback';

// contextos
import { useLogin } from './Login/LoginContext';

// importa as páginas
import Login from "./Login";
import Cabecalho from "./Cabecalho";
import Rodape from "./Rodape"
import PainelUsuario from "./PainelUsuario";
import TrocarSenha from "./TrocarSenha";
import ComprarPassagem from "./ComprarPassagem";
import Banners from "./Banners";
import Sac from "./Sac";
import Museu from "./Museu";

const EventosApresentar = React.lazy(() => import('./Eventos/Apresentar'));
const Eventos = React.lazy(() => import('./Eventos'));
const Cadastrar = React.lazy(() => import('./Cadastrar'));

// landing page
export default function Site(){

    // estados
    const login = useLogin();
    
    return <>
        <Cabecalho />

        <Box
            minH={400}
        >
            <Routes>
                <Route index element={<Banners />} />
                <Route path={"/passeios" } element={<Suspense fallback={<Fallback />} > <Eventos key="passeios" tipo="passeio" /> </Suspense>}  />
                <Route path={"/passeios/:id"} element={<Suspense fallback={<Fallback />} > <EventosApresentar /> </Suspense>} />
                <Route path={"/passeios-comprar" } element={<Suspense fallback={<Fallback />} > <Eventos key="passeios-venda" tipo="passeio" venda /> </Suspense>} />
                <Route path={"/eventos" } element={<Suspense fallback={<Fallback />} > <Eventos key="eventos" tipo="evento" /> </Suspense>} />
                <Route path={"/eventos/:id"} element={<Suspense fallback={<Fallback />} > <EventosApresentar /> </Suspense>} />
                <Route path={"/acervo" } element={<Suspense fallback={<Fallback />} > <Eventos key="acervo" tipo="acervo" /> </Suspense>} />
                <Route path={"/acervo/:id"} element={<Suspense fallback={<Fallback />} > <EventosApresentar /> </Suspense>} />
                {/* <Route path={"/museu" } element={<Suspense fallback={<Fallback />} > <Eventos key="museu" tipo="museu" /> </Suspense>} /> */}
                <Route path={"/museu" } element={<Suspense fallback={<Fallback />} > <Museu /> </Suspense>} />
                <Route path={"/sac" } element={<Sac />} />

                {login.autenticado && 
                    <>
                        <Route path={"/painel-usuario/*" } element={<PainelUsuario />} />
                        <Route path="/login" element={<Navigate replace to="/painel-usuario" />} />
                        <Route path="/passeios-comprar/:id" element={<ComprarPassagem />} />
                    </>
                }

                {!login.autenticado && 
                    <>
                        <Route path="/trocar-senha" element={<TrocarSenha />} />
                        <Route path="/cadastrar" element={<Suspense fallback={<Fallback />} > <Cadastrar /> </Suspense>} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/painel-usuario/*" element={<Navigate replace to="/login" />} />
                        <Route path="/passeios-comprar/:id" element={<Login />} />
                    </>
                }

                <Route path="*" element={<>Não encontrado</>} />
            </Routes>
        </Box>

        <Rodape />

    </>


}