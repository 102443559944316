import React from "react";
import { Box } from "@chakra-ui/react";
import * as R from "ramda";

import TremMenu from "./../../../Componentes/TremCarro/TremMenu";
import { useComprarPassagem } from "../ComprarPassagemContext";

export default function DesenhoTrem(){

    const { noturno, maiorQue1024, carros, carroSelecionado, alterarCarroSelecionado } = useComprarPassagem();
    let bg = noturno ? "gray.400" : "white";

    return <>
        <Box bg={bg} p={1} rounded={5} mb={5}>
            <TremMenu
                lista={carros.map(carro => carro.dados)}
                clicouCarro={(carro, posicao) => {
                    alterarCarroSelecionado(posicao);
                }}
                carroFocado={carros[carroSelecionado].dados.id}
                wh={maiorQue1024 ? "98px" : "58px"}
                bg={bg}
                carrosLotados={
                    carros
                    .filter(carro => {
                        return carro.dados.liberarVenda === "N" || carro.vagas.filter(v => v.dados.ocupada === "N").length === 0;
                    })
                    .map(carro => carro.dados.id)
                }

            />
            
        </Box>
    </>
}