import React, {
    useState,
    createContext,
    useContext,
    useEffect
} from 'react';
import { useMediaQuery, useColorModeValue } from '@chakra-ui/react';

const PainelUsuarioContext = createContext({});

// contexto de login
export function PainelUsuarioProvider({ children }) {

    const [ maiorQue768 ] = useMediaQuery('(min-width: 768px)');
    const [ maiorQue1024 ] = useMediaQuery('(min-width: 1024px)');
    const noturno = useColorModeValue(false, true);
    const [ menuOpcao, alterarMenuOpcao ] = useState("");

    return (
        <PainelUsuarioContext.Provider
            value={{
                maiorQue768, maiorQue1024, noturno,
                menuOpcao, alterarMenuOpcao
            }}
        >
            {children}
        </PainelUsuarioContext.Provider>
    );
}

// finaliza
export function usePainelUsuario() {
    const context = useContext(PainelUsuarioContext);
    return context;
}