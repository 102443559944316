import React, { useState } from "react";
import { Box, Button } from "@chakra-ui/react";

import { useTremCarro } from "./../TremCarroContext";

// cria carro
export default function Carro(){

    // estados
    const { dadosCarro, dadosVagas, dimensao, inserir, inseriuVaga } = useTremCarro();
    const [ blocoAtivo, alterarBlocoAtivo ] = useState({x:0, y:0});

    // função para verificar a posição do mouse e destacar os bloquinhos
    function destacarBloco(x, y){

        // se não tiver habilitado, ignora
        if(!inserir) return false;

        // verifica os blocos para iluminar
        if(
            x >= blocoAtivo.x && x <= blocoAtivo.x + 1 &&
            y >= blocoAtivo.y && y <= blocoAtivo.y + 1 
        ){
            return true;
        }
        return false;
    }

    // verifica se ambos os objetos se tocam em algum momento
    function colisao(a) {

        // posição atual
        let b = {
            x1: blocoAtivo.x,
            x2: blocoAtivo.x + 1,
            y1: blocoAtivo.y,
            y2: blocoAtivo.y + 1
        };

        // has horizontal gap
        if (a.x1 > b.x2 || b.x1 > a.x2) return false;
    
        // has vertical gap
        if (a.y1 > b.y2 || b.y1 > a.y2) return false;
    
        return true;
    }

    // ao clicar sobre bloco, verifica se o mesmo está ocupado
    function verificarOcupado(){        
        
        // busca as vagas que tocam a vaga que está tentando cadastrar
        let ocupados = dadosVagas.filter(vagaPos => {
            return colisao({
                x1: vagaPos.desenho.x,
                x2: vagaPos.desenho.x + 1,
                y1: vagaPos.desenho.y,
                y2: vagaPos.desenho.y + 1
            });
        });

        // se está ocupada
        if(ocupados.length > 0){
            return true;
        }

        // finaliza
        return false;
    }

    // cria blocos
    let blocos = () => {

        // cria blocos
        let arrayBlocos = [];
        for(let x = 0; x < dadosCarro.desenho.w; x++){
            for(let y = 0; y < dadosCarro.desenho.h; y++){

                // verifica se está na posição para deixar plotar
                let acenderBloco = destacarBloco(x, y);

                arrayBlocos.push(
                    <Box 
                        key={`${x}-${y}`}
                        as="g"
                        transform={`translate(${x * dimensao.bloco.w}px,${y * dimensao.bloco.h}px)`}
                        onMouseOver={() => {
                            alterarBlocoAtivo({x, y});
                        }}
                    >
                        <Box 
                            as="rect"
                            w={dimensao.bloco.w - 4}
                            h={dimensao.bloco.h - 4}
                            fill={`${ acenderBloco ? "green.300" : "transparent"}`}
                            cursor={inserir ? "pointer" : "default"}
                            transform="translate(2,2)"
                            stroke="gray.500"
                            strokeWidth={0.5}
                            strokeDasharray={1}
                            onClick={() => {
                                if(inserir && !verificarOcupado()){
                                    inseriuVaga(blocoAtivo);
                                }
                            }}
                        />
                    </Box>
                );
            }
        }

        // finaliza
        return arrayBlocos;

    };


    return <>
        {blocos()}
    </>

}