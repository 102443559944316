import React, { useEffect } from "react";
import { useDisclosure, Center, Box, Text, Container, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Button } from "@chakra-ui/react";
import { useComprarPassagem } from "./../ComprarPassagemContext";
import { useNavigate } from "react-router-dom";


// venda finalizada com sucesso
export default function Finalizada(){

    const navigate = useNavigate();
    const { dados } = useComprarPassagem();
    const { isOpen, onOpen, onClose } = useDisclosure(true)

    useEffect(() => {

        // limpa restos
        window.localStorage.removeItem(`passageirosTemp_${dados.id}`);
        window.localStorage.removeItem(`passageirosTempTime_${dados.id}`);

    }, []);

    // useEffect(() => {
    //     setTimeout(() => {
    //         navigate(`/painel-usuario/pedidos`);
    //     }, 4000);
    // }, []);


    return <>
        <Container
            maxW='container.sm'
            my={100}
        >   
            <Modal isOpen={true} variant={"success"} isCentered size={"lg"}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader textAlign={"center"}>Parabéns!</ModalHeader>
                <ModalBody>
                    <Center my={5} textAlign={"center"} fontSize={"2xl"}>
                        Sua compra foi finalizada com sucesso, você receberá um email assim que seu pagamento for aprovado pelo Pagseguro.
                    </Center>
                </ModalBody>

                <ModalFooter>
                    <Button 
                        colorScheme='green' 
                        mr={3} 
                        onClick={() => {
                            navigate(`/painel-usuario/pedidos`);
                        }} 
                        isFullWidth
                    >
                        Meus pedidos
                    </Button>
                </ModalFooter>
                </ModalContent>
            </Modal>
        </Container>
    </>

}