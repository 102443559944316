import React, { useEffect, useState } from "react";
import axios from "axios";
import * as R from "ramda"

import { Box, Input, Container, Flex, Image, Heading, Divider, Button, useToast } from "@chakra-ui/react";
import { useComprarPassagem } from "./../../ComprarPassagemContext";
import Parcelamento from "./Parcelamento";
import useIsMounted from 'ismounted';
import DadosDonoCartao from "./DadosDonoCartao";

export default function CartaoCredito(){

    const isMounted = useIsMounted();
    const toast = useToast();
    const { maiorQue1024, dados, noturno, pagseguroCartoes, dadosCartao, alterarDadosCartao, pagamento, alterarPagamento, listaPassageiros, processandoPagamento, alterarProcessandoPagamento, alterarErrosPassageiros, alterarErrosPagamento, alterarEtapa } = useComprarPassagem();
    const [ cvvSize, alterarCvvSize ] = useState(20);
    
    // carrega a bandeira
    useEffect(() => {

        // carrega a bandeira
        if(dadosCartao.numero.length > 5 && pagamento.bandeiraNome === null){
            carregarBandeira();
        }

        if(dadosCartao.numero.length <= 5 && pagamento.bandeiraNome !== null){
            let dadosPagamento = R.mergeDeepRight(pagamento, { bandeiraNome: null });
            alterarPagamento(dadosPagamento);
        }

    }, [ dadosCartao.numero]);

    // cria token do cartão
    useEffect(() => {
        
        if(
            pagamento.bandeiraNome !== null &&
            dadosCartao.numero.length > 15 &&
            dadosCartao.mes.length > 1 &&
            dadosCartao.ano.length > 3 &&
            dadosCartao.codigoSeguranca.length >= cvvSize
            
        ){
            criarTokenCartao();
        }
        
    }, [ dadosCartao, pagamento.bandeiraNome ]);

    // carregar bandeira
    function carregarBandeira(){
        
        // requisição para carregar a bandeira
        window.PagSeguroDirectPayment.getBrand({ 
            cardBin: dadosCartao.numero,
            success: ({ brand }) => {
                let dadosPagamento = R.mergeDeepRight(pagamento, { 
                    bandeiraNome: brand.name
                });
                alterarPagamento(dadosPagamento);
                alterarCvvSize(brand.cvvSize);
            },
            error: (e) => {
                console.log(e)
            },
            complete: () => {}
        });
    }

    // cria token do cartão de crédito
    function criarTokenCartao(){
        
        // valida para dar sequencia
        window.PagSeguroDirectPayment.createCardToken({
            cardNumber: dadosCartao.numero,
            brand: pagamento.bandeiraNome,
            cvv: dadosCartao.codigoSeguranca,
            expirationMonth: dadosCartao.mes,
            expirationYear: dadosCartao.ano,
            success: retorno => {
                let novoPagamento = R.mergeDeepRight(pagamento, {tokenCartao: retorno.card.token});
                alterarPagamento(novoPagamento);
            },
            error: retorno => {},
            complete: retorno => {}
        });

    }

    // finalizar pagamento
    async function processarPagamento(){
        alterarProcessandoPagamento(true);
        alterarErrosPassageiros([]); 
        alterarErrosPagamento([]);

        // pega a hash
        let dadosPagamento = null;
        if(pagamento.hash === null){
            let hash = null;
            try{
                hash = await new Promise((finalizado, rejeitado) => {
                    window.PagSeguroDirectPayment.onSenderHashReady(retorno => {
                        if(retorno.status === 'error') {
                            rejeitado();
                        }
                        finalizado(retorno.senderHash);
                    });
                });
            }catch(error){
                alterarProcessandoPagamento(false);
                return false;
            }

            // inclui a hash
            dadosPagamento = R.mergeDeepRight(pagamento, { hash: hash });
            alterarPagamento(dadosPagamento);
        }else{
            dadosPagamento = R.clone(pagamento);
        }
        
        // faz a requisição para a compra
        try{

            let { data } = await axios.post(`/transacao/finalizar/cartaoCredito`, {
                idEvento: dados.id,
                dados: dadosPagamento,
                passageiros: listaPassageiros
            });

            // redirecionar para tela de venda finalizada
            alterarEtapa("finalizada");

            // Venda finalizada
            toast({
                title: 'Parabéns! Sua compra foi efetuada com sucesso!',
                description: "Você receberá um email assim que seu pagamento for aprovado pelo Pagseguro",
                status: 'success',
                duration: 9000,
                isClosable: true,
            });
            
        }catch({response}){
            console.log(response.data);
            if(response && response.status && response.status === 400){

                // passeio indisponivel
                if(response.data.passeioIndisponivel){
                    toast({
                        title: 'Passeio indisponivel para vendas!',
                        // description: "Esse passeio já não está mais disponivel para vendas",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }

                if(response.data.passageiros){
                    alterarErrosPassageiros(response.data.passageiros);
                    alterarEtapa(maiorQue1024 ? "vagas" : "passageiros");
                    toast({
                        title: 'Verifique as informações dos passageiros!',
                        // description: "Esse passeio já não está mais disponivel para vendas",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
                if(response.data.pagamento){
                    alterarErrosPagamento(response.data.pagamento);
                    toast({
                        title: 'Verifique os dados pessoais do seu cartão!',
                        // description: "Esse passeio já não está mais disponivel para vendas",
                        status: 'error',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            }
        }finally{
            alterarProcessandoPagamento(false);
        }
    }

    // useEffect(() => {

    //         // Venda finalizada
    //         toast({
    //             title: 'Parabéns! Sua compra foi efetuada com sucesso!',
    //             description: "Você receberá um email assim que seu pagamento for aprovado pelo Pagseguro",
    //             status: 'success',
    //             duration: 9000,
    //             isClosable: true,
    //         });
    // }, []);

    return <>
        <Container
            bg={noturno ? "gray.900" : "gray.100"}
            p={5}
            mb={5}
            rounded={5}
            mx="auto"
        >
            
            <Flex align={"center"} justify={"space-between"}>
                <Heading size="sm">Cartão de crédito</Heading>
                <Box>
                    <DadosDonoCartao />
                </Box>
            </Flex>
            <Divider my={3} />

            <Box mb={5}>
                <Flex
                    align={"center"}
                    mb={2}
                >
                    <Input 
                        value={dadosCartao.numero}
                        onChange={e => {
                            alterarDadosCartao({...dadosCartao, numero: e.target.value});
                        }}
                        maxLength={16}
                        placeholder="Número do cartao de crédito"
                    />
                    {(pagamento.bandeiraNome !== null) && <>
                        <Image ml={2} verticalAlign={"top"} alt='' src={`https://stc.pagseguro.uol.com.br/public/img/payment-methods-flags/68x30/${pagamento.bandeiraNome}.png`} />
                    </>}
                </Flex>

                <Flex align={"center"}>
                    <Input 
                        mb={2}
                        placeholder="Mês"
                        value={dadosCartao.mes}
                        onChange={e => {
                            alterarDadosCartao({...dadosCartao, mes: e.target.value});
                        }}
                        maxLength={2}
                        maxW={20}
                        mr={2}
                    />
                    <Input 
                        mb={2}
                        placeholder="Ano" 
                        value={dadosCartao.ano}
                        onChange={e => {
                            alterarDadosCartao({...dadosCartao, ano: e.target.value});
                        }}
                        onBlur={e => {
                            if(e.target.value.length === 2){
                                alterarDadosCartao({...dadosCartao, ano: `20${e.target.value}`});
                            }
                        }}
                        maxLength={4}
                        maxW={20}
                    />
                    <Box flex={1}></Box>
                    <Input 
                        width={"auto"}
                        mb={2}
                        placeholder="CVV" 
                        value={dadosCartao.codigoSeguranca}
                        onChange={e => {
                            alterarDadosCartao({...dadosCartao, codigoSeguranca: e.target.value});
                        }}
                        maxW={100}
                    />
                </Flex>
            
            </Box>

            <Box mb={5}>
                { pagamento.bandeiraNome !== null && 
                    <>
                        <Parcelamento />
                    </>
                }
            </Box>
            
            <Box textAlign={"right"}>
                <Button
                    variant={"solid"}
                    colorScheme={"green"}
                    isDisabled={pagamento.bandeiraNome === null || pagamento.tokenCartao === null}
                    onClick={processarPagamento}
                    isLoading={processandoPagamento}
                >
                    Processar Pagamento
                </Button>
            </Box>
        </Container>
    </>

}