import React from "react";
import { Box, Flex, Image } from "@chakra-ui/react";
import { useComprarPassagem } from "../ComprarPassagemContext";
import moment from "moment";

export default function InformacaoPasseio(){

    const { dados, card, noturno, maiorQue768 } = useComprarPassagem();
    
    return <>
        <Flex mb={5} alignItems={"center"} flexWrap={"wrap"} bg={noturno ? "whiteAlpha.100" : "gray.100"} p={2} rounded={2}>
            {card &&
                <Image  
                    maxW={maiorQue768 ? "150px" : "none"}
                    mb={maiorQue768 ? 0 : 5}
                    w="100%"
                    rounded={1}
                    src={`${process.env.REACT_APP_URL_ARQUIVOS}/anexos/${card.temporario}`}
                />
            }

            <Box flex={1} textAlign={"center"} minW={"300px"}>
                <Box fontWeight={"bold"} mb={2}>{(dados.saidaPartida) && <>{ moment(dados.saidaPartida).format("DD/MM/YYYY") }</>}</Box>
                <Box>{dados.titulo}</Box>
                <Box fontSize={"xs"}>{dados.informacao}</Box>
            </Box>
        </Flex>

    </>

}