import React, { Suspense } from "react";
import { useLogin } from './Site/Login/LoginContext';
import { Routes, Route } from "react-router-dom";
import { PainelProvider } from "./Painel/PainelContext";

// componentes simples
import Fallback from './Componentes/Fallback';
import Site from "./Site";

// carrega componente lentamente
const Painel = React.lazy(() => import('./Painel'));
// const Site = React.lazy(() => import('./Site'));

// sistema
export default function Sistema(){

    // estados
    const login = useLogin();
    
    return <>
        <Routes>
            <Route 
                path={"/*"}
                index
                // element={<Suspense fallback={<Fallback />}> <Site /></Suspense>}
                element={<Site />}
            />

            {/* área restrita */}
            { (login.autenticado && ["S", "A", "V", "O"].includes(login.dados.tipoUsuario)) ?
                <Route 
                    path="/painel/*" 
                    element={
                        <Suspense 
                            fallback={<Fallback />}
                        > 
                            <PainelProvider>
                                <Painel />
                            </PainelProvider>
                        </Suspense>
                    }
                />
                :
                <Route 
                    path="/painel/*" 
                    element={<>Não autorizado</>}
                />
            }
        </Routes>
    </>

}