import React, { useEffect } from "react";
import { Box, Input, Container, Flex, Image, Heading, Divider, Button, useToast, useDisclosure } from "@chakra-ui/react";
import { useComprarPassagem } from "./../../../ComprarPassagemContext";
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react';
import * as R from "ramda";

import FormularioCadastro from "./../../../../PainelUsuario/MeusDados/FormularioCadastro";

// dados do dono do cartão
export default function DadosDonoCartao(){

    const { maiorQue1024, dados, noturno, pagamento, errosPagamento, processandoPagamento, alterarPagamento } = useComprarPassagem();
    const { isOpen, onOpen, onClose } = useDisclosure();

    useEffect(() => {
        if(Object.keys(errosPagamento).length){
            onOpen();
        }
    }, [errosPagamento]);

    return <>

        <Button 
            size="sm" 
            colorScheme={Object.keys(errosPagamento).length ? "red" : "blue"}
            disabled={processandoPagamento}
            onClick={onOpen}
        >Dados pessoais do cartão</Button>

        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Dados do cartão</ModalHeader>
                <ModalCloseButton />
                <ModalBody>

                    <FormularioCadastro 
                        size="sm"
                        dados={pagamento.dadosDono}
                        alterarDados={dadosNovo => {
                            let novosDadosDono = R.clone(pagamento);
                            Object.assign(novosDadosDono.dadosDono, dadosNovo);
                            alterarPagamento(novosDadosDono);
                        }}
                        erros={errosPagamento}
                    />

                </ModalBody>

                <ModalFooter>
                    <Button colorScheme='blue' mr={3} onClick={onClose}>
                    Fechar
                    </Button>
                    {/* <Button variant='ghost'>Secondary Action</Button> */}
                </ModalFooter>
            </ModalContent>
        </Modal>
    </>

}