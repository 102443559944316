import React, {
    useState,
    createContext,
    useContext,
    useEffect
} from 'react';
import { useMediaQuery, useColorModeValue } from '@chakra-ui/react';
import * as R from "ramda";
import { useLogin } from "./../Login/LoginContext";
import { intersectObject } from './../../Helpers';

const ComprarPassagemContext = createContext({});

// contexto de login
export function ComprarPassagemProvider({ children }) {

    // estados globais
    const login = useLogin();
    const [ maiorQue1024 ] = useMediaQuery('(min-width: 1024px)');
    const [ maiorQue768 ] = useMediaQuery('(min-width: 768px)');
    const noturno = useColorModeValue(false, true);
    const [ etapa, alterarEtapa ] = useState("vagas"); // vagas, passageiros, pagamento, finalizada
    const [ dados, alterarDados ] = useState(null);
    const [ carros, alterarCarros ] = useState([]);
    const [ passagens, alterarPassagens ] = useState([]);
    const [ ambientePagseguro, alterarAmbientePagseguro ] = useState(null);
    const [ carroSelecionado, alterarCarroSelecionado ] = useState(0);
    const [ focarVaga, alterarFocarVaga ] = useState(null);
    const [ listaPassageiros, alterarListaPassageiros ] = useState([]);
    const [ pagseguroCartoes, alterarPagseguroCartoes ] = useState([]);
    const [ metodosPagamento, alterarMetodosPagamento ] = useState([]);
    const [ processandoPagamento, alterarProcessandoPagamento ] = useState(false);
    const [ errosPassageiros, alterarErrosPassageiros ] = useState([]);
    const [ errosPagamento, alterarErrosPagamento ] = useState([]);
    const [ card, alterarCard ] = useState(null);
    // const [ dadosDonoCartao, alterarDadosDonoCartao ] = useState({
    //     token: null,
    //     // bandeiraNome: null,
    //     // total: 118.80
    // });
    const [ dadosCartao, alterarDadosCartao ] = useState({
        numero: "", // 4111111111111111
        mes: "",
        ano: "",
        codigoSeguranca: ""
    });
    const [ pagamento, alterarPagamento ] = useState({
        bandeiraNome: null,
        tokenCartao: null,
        bancoNome: null,
        hash: null,
        quantidadeParcelas: 1,
        valorParcelas: null,
        totalValor: 0.0,
        dadosDono: intersectObject({
            donoCartao: "N",
            nome: "",
            rg: "",
            cpf: "",
            nascimento: null,
            telefoneResidencial: "",
            telefoneCelular: "",
            endereco: "",
            numero: "0",
            bairro: "",
            cep: "",
            cidade: "",
            estado: ""
        }, login.dados)
    });

    // função para incluir novo registro de passageiro
    function incluirPassageiro(dadosVaga){

        // se essa vaga já tiver sido passada, não executa
        let jaOcupou = listaPassageiros.find(lp => lp.vaga.id === dadosVaga.id);
        if(jaOcupou){
            return;
        }

        // incrementa passageiro
        let novaLista = R.clone(listaPassageiros);
        let novoPassageiro = {
            // idEvento: null // incluído no back
            carro: R.clone(carros[carroSelecionado].dados),
            vaga: R.clone(dadosVaga),
            dados: {
                idPasseioVaga: dadosVaga.id,
                idPasseioPassagem: passagens[0].dados.id,
                nome: "",
                cpf: "",
                rg: "",
                telefone: "",
                nascimento: null,
                deficiente: "N",
                deficienteObservacao: "",
                possuiCrianca: "N"
            },
            crianca: {
                nome: "",
                cpf: "",
                rg: "",
                certidao: "",
                nascimento: null
            }
        };

        // se for o primeiro, utilizar os dados de login do usuário
        if(listaPassageiros.length === 0){
            let { nome, cpf, rg, telefoneCelular, nascimento } = login.dados;
            novoPassageiro = R.mergeDeepRight(novoPassageiro, {
                dados: { nome, nascimento, cpf, rg: rg ?? "", telefone: telefoneCelular ?? "" }
            });
        }

        // incrementa
        console.log(novoPassageiro);
        novaLista.push(novoPassageiro);
        alterarListaPassageiros(novaLista);

    }

    // calcular total das passagens
    useEffect(() => {

        // soma o total das passagens selecionadas pelos passageiros
        let totalValor = listaPassageiros.reduce((soma, lp) => {
            let passagem = passagens.find(p => p.dados.id === lp.dados.idPasseioPassagem);
            return parseFloat(passagem.dados.valor) + soma;
        }, 0);

        // altera pagamento informando o total novo
        let novoPagamento = R.mergeDeepRight(pagamento, { totalValor });
        alterarPagamento(novoPagamento)

    }, [ listaPassageiros ]);

    return (
        <ComprarPassagemContext.Provider
            value={{
                dados, alterarDados,
                card, alterarCard, maiorQue768,
                maiorQue1024, noturno,
                etapa, alterarEtapa,
                passagens, alterarPassagens,
                carros, alterarCarros,
                ambientePagseguro, alterarAmbientePagseguro,
                focarVaga, alterarFocarVaga,
                carroSelecionado, alterarCarroSelecionado,
                incluirPassageiro,
                listaPassageiros, alterarListaPassageiros,
                metodosPagamento, alterarMetodosPagamento,

                // estados de pagamento
                dadosCartao, alterarDadosCartao,
                pagamento, alterarPagamento,
                pagseguroCartoes, alterarPagseguroCartoes,
                processandoPagamento, alterarProcessandoPagamento,


                // erros
                errosPassageiros, alterarErrosPassageiros,
                errosPagamento, alterarErrosPagamento
            }}
        >
            {children}
        </ComprarPassagemContext.Provider>
    );
}

// finaliza
export function useComprarPassagem() {
    const context = useContext(ComprarPassagemContext);
    return context;
}