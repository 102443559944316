import React from "react";
import { Flex, Image, Box, Center, Text } from "@chakra-ui/react";

import vagaLivre from "./../Vagas/icones/poltrona_t1/poltrona_t1_1_0.png";
import vagaEscolhida from "./../Vagas/icones/poltrona_t1/poltrona_t1_2_0.png";
import vagaIndisponivel from "./../Vagas/icones/poltrona_t1/poltrona_t1_3_0.png";


export default function LegendaVagas(){

    return <>

        <Flex mb={2} gridGap={2} alignItems={"center"} justify={"center"}>

            <Center bg={"#fdcd7b"} p={1} py={1} rounded={5}>
                <Image src={vagaLivre} boxSize={6} mr={1} />
                <Text color="gray.600" fontSize={"xs"}>Livre</Text>
            </Center>
            <Center bg={"#fdcd7b"} p={1} py={1} rounded={5}>
                <Image src={vagaIndisponivel} boxSize={6} mr={1} />
                <Text color="gray.600" fontSize={"xs"}>Indisponivel</Text>
            </Center>
            <Center bg={"#fdcd7b"} p={1} py={1} rounded={5}>
                <Image src={vagaEscolhida} boxSize={6} mr={1} />
                <Text color="gray.600" fontSize={"xs"}>Escolhida</Text>
            </Center>

        </Flex>

    </>

}