import { ColorModeScript } from '@chakra-ui/react';
import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
// import App from './App';
import Sistema from './Sistema';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from "react-router-dom";
import { LoginProvider } from './Sistema/Site/Login/LoginContext';
import { ChakraProvider } from '@chakra-ui/react';
import customTheme from "./customTheme";
import "./axiosConfig";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "flatpickr/dist/themes/light.css";
import 'moment/locale/pt-br';
import "./global.scss";


ReactDOM.render(
    <StrictMode>
        <ColorModeScript />
        {/* <App /> */}
        <BrowserRouter>
            <LoginProvider>
                <ChakraProvider theme={customTheme}>
                    <DndProvider backend={HTML5Backend}>
                        <Sistema />
                    </DndProvider>
                </ChakraProvider>
            </LoginProvider>
        </BrowserRouter>
    </StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
