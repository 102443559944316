import React, { useEffect, useState } from "react";
import axios from "axios";
import { Button, Box, useToast, Container } from "@chakra-ui/react";
import * as R from "ramda";

import FormasPagamento from "./FormasPagamento";
import CartaoCredito from "./CartaoCredito";
import CartaoDebito from "./CartaoDebito";
import RelatorioPassagens from "./RelatorioPassagens";

import { useComprarPassagem } from "./../ComprarPassagemContext";
import { FaArrowLeft } from "react-icons/fa";

// pagode
export default function Pagamento(){

    const toast = useToast();
    const { metodosPagamento, alterarEtapa, maiorQue1024, processandoPagamento } = useComprarPassagem();

    console.log(metodosPagamento);

    return <>
            
            {/* criar relatório de passagens */}
            <RelatorioPassagens />

            {/* mostrar as formas de pagode */}
            <FormasPagamento />

            {/* se o metodo for cartão de crédito */}
            { (metodosPagamento["CREDIT_CARD"]) && <>
                <CartaoCredito />
            </>}

            {/* se o metodo for débito online */}
            { (metodosPagamento["ONLINE_DEBIT"]) && <>
                <CartaoDebito />
            </>}


            <Container
                p={0}
            >
                <Box>
                    <Button
                        onClick={() => alterarEtapa(maiorQue1024 ? "vagas" : "passageiros")}
                        leftIcon={<FaArrowLeft />}
                        isDisabled={processandoPagamento}
                    >
                        Passageiros
                    </Button>
                </Box>
            </Container>
    </>

}