import React, { useEffect, useState, createRef } from "react";
import axios from "axios";
import { Flex, Spinner, Checkbox, Link, FormControl, Radio, FormHelperText, Divider, FormLabel, InputLeftElement, Input, InputGroup, Badge, Box, Button, Grid, Center, GridItem, useToast, Text, useMediaQuery, useColorModeValue, Container, Alert, AlertIcon, Textarea } from "@chakra-ui/react";
import { useComprarPassagem } from "../ComprarPassagemContext";
import { FaCalendarDay, FaCouch, FaPhone, FaTicketAlt, FaTimes, FaTrain, FaTrash } from "react-icons/fa";
import { HiOutlineTicket } from "react-icons/hi";
import { MdChair } from "react-icons/md";
import * as R from "ramda";
import { DebounceInput } from 'react-debounce-input';
import { converterReal } from "./../../../Helpers";
import InputData from "./../../../Componentes/InputData";
import InputCpf from "./../../../Componentes/InputCpf";
import InputTel from "./../../../Componentes/InputTel";
import moment from "moment";


export default function Passageiros(){

    const { maiorQue1024, noturno, dados, alterarFocarVaga, listaPassageiros, alterarListaPassageiros, passagens, alterarEtapa, errosPassageiros, alterarErrosPassageiros } = useComprarPassagem();


    useEffect(() => {
        // console.log(window.localStorage.getItem(`passageirosTemp_${dados.id}`));
        let passageirosSalvo = JSON.parse(window.localStorage.getItem(`passageirosTemp_${dados.id}`));
        let passageirosSalvoTempo = JSON.parse(window.localStorage.getItem(`passageirosTempTime_${dados.id}`));
        
        if(passageirosSalvo){

            // mantém salvo até 5 minutos
            if((parseInt(passageirosSalvoTempo) + (5 * 60000)) < new Date().getTime()){
                window.localStorage.removeItem(`passageirosTemp_${dados.id}`);
                window.localStorage.removeItem(`passageirosTempTime_${dados.id}`);
            }else{
                alterarListaPassageiros(passageirosSalvo);
            }

        }
    }, []);

    useEffect(() => {

        if(!listaPassageiros.length) return;

        if(!window.localStorage.getItem(`passageirosTempTime_${dados.id}`)){
            window.localStorage.setItem(`passageirosTempTime_${dados.id}`, new Date().getTime());
        }
        window.localStorage.setItem(`passageirosTemp_${dados.id}`, JSON.stringify(listaPassageiros));

    }, [listaPassageiros]);


    return <>

        <Box alignSelf="start" flex={1} pr={maiorQue1024 ? 5 : 0}>

            { listaPassageiros.length === 0 && <>
                <Center p={5}>
                    <MdChair /> <Text ml={2}>Escolha suas poltronas!</Text>
                </Center>
            </>}

            {listaPassageiros.map((passageiro, pos) => {

                let erros = errosPassageiros[pos] ?? [];
                let errosCrianca = erros.crianca ?? [];

                return <Box
                    key={passageiro.vaga.id}
                    bg={noturno ? "gray.700" : "white"}
                    borderColor={noturno ? "gray.900" : "gray.200"}
                    {...{
                        [errosPassageiros[pos] && `borderColor`]: "red.500"
                    }}
                    boxShadow={"2xl"}
                    borderWidth={3}
                    flex={1}
                    rounded={5}
                    p={1}
                    ml={maiorQue1024 ? 5 : 0}
                    alignSelf={maiorQue1024 ? "start" :  "center"}
                    w="100%"
                    onMouseEnter={() => {
                        alterarFocarVaga(passageiro.vaga.id);
                    }}
                    onMouseLeave={() => {
                        alterarFocarVaga(null);
                    }}
                    mb={5}
                    pb={2}
                >
                    {erros.vagaOcupada && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.vagaOcupada}</Text>}
                    <Flex justify="space-between" align="center" mb={1} p={1} pb={1}>
                        <Flex>
                            <Button
                                mr={2}
                                size="xs"
                                h={"auto"}
                                p={0}
                                colorScheme={"red"}
                                onClick={() => {
                                    let novaListaPassageiros = R.clone(listaPassageiros);
                                    novaListaPassageiros.splice(pos, 1);
                                    alterarListaPassageiros(novaListaPassageiros);
                                    alterarErrosPassageiros([]);
                                }}
                            >
                                <FaTimes />
                            </Button>
                            <Flex fontSize="sm" align="center" mr={2} bg={"yellow.200"} px={2} py={1} rounded={5}>
                                <FaTrain color="black" /> <Text ml={2} fontSize={"xs"} fontWeight="bold" color={"gray.700"}>{passageiro.carro.codigo}</Text>
                            </Flex>
                            <Flex fontSize="sm" align="center" bg={"green.200"} px={2} py={1} rounded={5}>
                                <MdChair color="green" /> <Text ml={2} fontSize={"xs"} fontWeight="bold" color={"gray.700"}>{passageiro.vaga.codigo}</Text>
                            </Flex>
                        </Flex>
                        <Box>
                            <Text 
                                as={Link} 
                                fontSize="sm"
                                onClick={() => {
                                    let novaLista = R.clone(listaPassageiros);
                                    novaLista[pos].dados.possuiCrianca = passageiro.dados.possuiCrianca === "N" ? "S" : "N";
                                    alterarListaPassageiros(novaLista);
                                }}
                            >
                                {passageiro.dados.possuiCrianca === "N" ? "Possui criança de colo?" : "Não possui criança de colo?"}
                            </Text>
                        </Box>
                    </Flex>

                    <Box  mb={maiorQue1024 ? 2 : 0}>
                        <Box mb={2} px={1}>
                            <FormControl>
                                <Input 
                                    as={DebounceInput}
                                    debounceTimeout={300}
                                    size="sm" 
                                    placeholder="nome*" 
                                    value={passageiro.dados.nome}
                                    onChange={e => {
                                        let novaLista = R.clone(listaPassageiros);
                                        novaLista[pos].dados.nome = e.target.value;
                                        alterarListaPassageiros(novaLista);
                                    }}
                                    isInvalid={erros.nome}
                                />
                            </FormControl>
                        </Box>
                        <Flex flexWrap={maiorQue1024 ? "nowrap" : "wrap"}>
                            <FormControl mx={1}  mb={maiorQue1024 ? 0 : 2}>
                                <InputGroup size="sm">
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<FaCalendarDay color='gray.300' />}
                                    />
                                    <InputData 
                                        showHelper={false}
                                        minW="125px" 
                                        size="sm" 
                                        placeholder="Nascimento"  
                                        value={passageiro.dados.nascimento}
                                        onChange={e => {
                                            let novaLista = R.clone(listaPassageiros);
                                            novaLista[pos].dados.nascimento = e.target.value;
                                            alterarListaPassageiros(novaLista);
                                        }}
                                        isInvalid={erros.nascimento}
                                    />
                                </InputGroup>
                            </FormControl>
                            <FormControl  mx={1} mb={maiorQue1024 ? 0 : 2}>
                                <InputCpf 
                                    minW="130px" 
                                    size="sm" 
                                    placeholder="Cpf*"
                                    value={passageiro.dados.cpf}
                                    onChange={e => {
                                        let novaLista = R.clone(listaPassageiros);
                                        novaLista[pos].dados.cpf = e.target.value;
                                        alterarListaPassageiros(novaLista);
                                    }}
                                    isInvalid={erros.cpf}
                                />
                            </FormControl>
                            <FormControl  mx={1} mb={maiorQue1024 ? 0 : 2}>
                                <Input 
                                    minW="110px" 
                                    size="sm" 
                                    placeholder="RG/Doc. c/foto*"
                                    as={DebounceInput}
                                    debounceTimeout={300}
                                    value={passageiro.dados.rg}
                                    onChange={e => {
                                        let novaLista = R.clone(listaPassageiros);
                                        novaLista[pos].dados.rg = e.target.value;
                                        alterarListaPassageiros(novaLista);
                                    }}
                                    isInvalid={erros.rg}
                                />
                            </FormControl>
                            <FormControl  mx={1} mb={maiorQue1024 ? 0 : 2}>
                                <InputGroup size="sm">
                                    <InputLeftElement
                                        pointerEvents='none'
                                        children={<FaPhone color='gray.300' />}
                                    />
                                    <InputTel
                                        pl={7}
                                        minW="163px" 
                                        type="tel"  
                                        placeholder="Telefone"
                                        value={passageiro.dados.telefone}
                                        onChange={e => {
                                            let novaLista = R.clone(listaPassageiros);
                                            novaLista[pos].dados.telefone = e.target.value;
                                            alterarListaPassageiros(novaLista);
                                        }}
                                        isInvalid={erros.telefone}
                                    />
                                </InputGroup>
                            </FormControl>
                        </Flex>
                        <Box mt={2} px={1} color={"white"} fontSize={"sm"}>
                            {erros.nome && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.nome}</Text>}
                            {erros.nascimento && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.nascimento}</Text>}
                            {erros.cpf && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.cpf}</Text>}
                            {erros.rg && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.rg}</Text>}
                        </Box>
                    </Box>

                    {/* dados da criança */}
                    { passageiro.dados.possuiCrianca === "S" &&
                        <Box  mb={maiorQue1024 ? 2 : 0}>
                            <Divider mb={2} />
                            <Box mb={2} px={1}>
                                <FormControl>
                                    <Flex align="center">
                                        <FormLabel m={0} mr={2}>Criança</FormLabel>
                                        <Input 
                                            size="sm" 
                                            placeholder="nome" 
                                            value={passageiro.crianca.nome}
                                            onChange={e => {
                                                let novaLista = R.clone(listaPassageiros);
                                                novaLista[pos].crianca.nome = e.target.value;
                                                alterarListaPassageiros(novaLista);
                                            }}
                                            isInvalid={errosCrianca.nome}
                                        />
                                    </Flex>
                                </FormControl>
                            </Box>
                            <Flex flexWrap={maiorQue1024 ? "nowrap" : "wrap"}>
                                <FormControl mx={1}  mb={maiorQue1024 ? 0 : 2}>
                                    <InputGroup size="sm">
                                        <InputLeftElement
                                            pointerEvents='none'
                                            children={<FaCalendarDay color='gray.300' />}
                                        />
                                        <InputData 
                                            showHelper={false} 
                                            minW="125px" 
                                            size="sm" 
                                            placeholder="Nascimento"  
                                            value={passageiro.crianca.nascimento}
                                            onChange={e => {
                                                let novaLista = R.clone(listaPassageiros);
                                                novaLista[pos].crianca.nascimento = e.target.value;
                                                alterarListaPassageiros(novaLista);
                                            }}
                                            isInvalid={errosCrianca.nascimento}
                                        />
                                    </InputGroup>
                                </FormControl>
                                <FormControl  mx={1} mb={maiorQue1024 ? 0 : 2}>
                                    <InputCpf 
                                        minW="130px" 
                                        size="sm" 
                                        placeholder="Cpf"
                                        value={passageiro.crianca.cpf}
                                        onChange={e => {
                                            let novaLista = R.clone(listaPassageiros);
                                            novaLista[pos].crianca.cpf = e.target.value;
                                            alterarListaPassageiros(novaLista);
                                        }}
                                    />
                                </FormControl>
                                <FormControl  mx={1} mb={maiorQue1024 ? 0 : 2}>
                                    {/* <Input maxW={maiorQue1024 ? "110px" : "none"} size="sm" placeholder="Rg"  /> */}
                                    <Input 
                                        minW="110px" 
                                        size="sm" 
                                        placeholder="Rg"  
                                        value={passageiro.crianca.rg}
                                        onChange={e => {
                                            let novaLista = R.clone(listaPassageiros);
                                            novaLista[pos].crianca.rg = e.target.value;
                                            alterarListaPassageiros(novaLista);
                                        }}
                                    />
                                </FormControl>
                                <FormControl  mx={1} mb={maiorQue1024 ? 0 : 2}>
                                    <Input 
                                        minW="163px" 
                                        size="sm"  
                                        placeholder="Certidão"  
                                        value={passageiro.crianca.certidao}
                                        onChange={e => {
                                            let novaLista = R.clone(listaPassageiros);
                                            novaLista[pos].crianca.certidao = e.target.value;
                                            alterarListaPassageiros(novaLista);
                                        }}
                                    />
                                </FormControl>
                            </Flex>
                            {(
                                passageiro.crianca.nascimento && 
                                passageiro.crianca.nascimento.length === 10 &&
                                moment().diff(passageiro.crianca.nascimento, 'years') >= 6
                            ) && 
                                <Box p={1} py={1} pb={0}>
                                    <Alert status="warning" p={1} fontSize={"sm"} rounded={2}>
                                        <AlertIcon />
                                        São permitidas crianças de colo com até 5 anos!
                                    </Alert>
                                </Box>
                            }
                            <Box mt={2} px={1} color={"white"} fontSize={"sm"}>
                                {errosCrianca.nome && <Text px={1} rounded={3} bg="red.500" mb={1}>{errosCrianca.nome}</Text>}
                                {errosCrianca.nascimento && <Text px={1} rounded={3} bg="red.500" mb={1}>{errosCrianca.nascimento}</Text>}
                                {/* 
                                {erros.cpf && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.cpf}</Text>}
                                {erros.rg && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.rg}</Text>} */}
                            </Box>

                            <Divider my={2} />
                        </Box>
                    }

                    {/* passagens */}
                    <Box>
                        {passagens.map(passagem => 
                            <Flex
                                key={passagem.dados.id}
                                rounded={5}
                                p={1}
                            >
                                <Radio 
                                    colorScheme='green' 
                                    mr={2} size='lg' 
                                    isChecked={passagem.dados.id === passageiro.dados.idPasseioPassagem} 
                                    onClick={() => {
                                        let novaLista = R.clone(listaPassageiros);
                                        novaLista[pos].dados.idPasseioPassagem = passagem.dados.id;
                                        alterarListaPassageiros(novaLista);
                                    }}
                                />
                                <Flex 
                                    bg={noturno ? "gray.800" : "gray.100"}
                                    {...{
                                        [passagem.dados.id === passageiro.dados.idPasseioPassagem && `bg`]: noturno ? "green" : "green.200"
                                    }}
                                    align="center" 
                                    fontSize="sm"
                                    px={2}
                                    py={1}
                                    rounded={5}
                                    flex={1}
                                    cursor={"pointer"}
                                    onClick={() => {
                                        let novaLista = R.clone(listaPassageiros);
                                        novaLista[pos].dados.idPasseioPassagem = passagem.dados.id;
                                        alterarListaPassageiros(novaLista);
                                    }}
                                >
                                    <Flex align="center">
                                        <Text minW={"70px"} textAlign={"center"}  fontWeight="bold"><small>R$</small> {converterReal(passagem.dados.valor)}</Text>
                                        <Box mt="1px" mx={2}>
                                            <HiOutlineTicket />
                                        </Box>
                                        <Text flex={1}>{passagem.dados.descricao}</Text>
                                    </Flex>
                                </Flex>
                            </Flex>
                        )}

                        {erros.passagemLiberada && <Text px={1} rounded={3} bg="red.500" mb={1}>{erros.passagemLiberada}</Text>}
                    </Box>

                    {/* necessidades */}
                    <Box px={1}>
                        <Box textAlign={"right"}>
                            <Button 
                                variant="link" 
                                fontSize={"sm"}
                                onClick={() => {
                                    let novaLista = R.clone(listaPassageiros);
                                    novaLista[pos].deficiente = novaLista[pos].deficiente === "S" ? "N" : "S";
                                    alterarListaPassageiros(novaLista);
                                }}
                            >
                                {passageiro.deficiente === "S" ? "Não possui necessidades?" : "Possui necessidades?"}
                            </Button>
                        </Box>
                        {passageiro.deficiente === "S" && 
                            <Textarea
                                placeholder="Descreva melhor as necessidades para que possamos melhor atendê-lo."
                                value={passageiro.deficienteObservacao}
                                onChange={e => {
                                    let novaLista = R.clone(listaPassageiros);
                                    novaLista[pos].deficienteObservacao = e.target.value;
                                    alterarListaPassageiros(novaLista);
                                }}
                            />
                        }
                    </Box>
                        
                </Box>

            })}

            <Flex justify="space-around">
                {!maiorQue1024 && 
                    <Center my={5}>
                        <Button size="lg" colorScheme="blue" onClick={() => alterarEtapa("vagas")} >Rever Vagas!</Button>
                    </Center>
                }

                <Center my={5}>
                    <Button 
                        size="lg" 
                        colorScheme="blue" 
                        onClick={() => alterarEtapa("pagamento")} 
                        isDisabled={listaPassageiros.length === 0}
                    >
                            Concluir!
                        </Button>
                </Center>
                
            </Flex>


        </Box>

    </>

}