import React from "react";
import { Grid, Center, Text } from "@chakra-ui/react";
import { useComprarPassagem } from "./../ComprarPassagemContext";

// etapas
export default function Etapas(){

    // container de etapas finalizado
    const { noturno, maiorQue1024, etapa } = useComprarPassagem();

    return <>
        <Grid 
            justifyContent="center" mb={5} 
            templateColumns={`repeat(${maiorQue1024 ? 2 : 1}, 1fr)`} 
            rounded={0}
        >
            {["vagas", "passageiros", "pagamento"].map( etapaItem => 
                <Center 
                    key={etapaItem}
                    bg={"whiteAlpha.100"} 
                    p={5} flexDirection="column" 
                    color={noturno ? "white" : "black"}
                    borderColor={"#00b90f"}
                    {...{
                        [etapaItem === etapa && `bg`]: noturno ? "green" : "#eeffeb",
                        [maiorQue1024 ? `borderBottomWidth` : `borderLeftWidth`]: 3,
                        [(etapaItem === "passageiros" && maiorQue1024) && `hidden`]: "hidden"
                    }}
                >
                    <Text fontWeight="bold" textTransform="capitalize">{etapaItem}</Text>
                    {etapaItem === etapa && 
                        <Text fontSize="sm">
                            Você está aqui
                        </Text>
                    }
                </Center>
            )}
        </Grid>
    </>

}